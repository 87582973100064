import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  faQrcode,
  faSitemap,
  faIndustry,
  faUser,
  faUserFriends,
  faSignOutAlt,
  faHome,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkContainer } from "react-router-bootstrap";
import { useMatch } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectSidebarCollapsed } from "./sidebarSlice";
import { useIsAuthenticated } from "@azure/msal-react";
import { handleSignOut } from "../../../auth/authProvider";
import { useGetInitialRightsByUserQuery } from "../../auth/authorizationQueries";
import RequiresRight from "../../auth/RequiresRight";
import {
  RIGHT_ADMINISTRATION_VIEW,
  RIGHT_COMPANY_VIEW,
  RIGHT_ORGANIZATION_VIEW,
  RIGHT_USERS_VIEW,
} from "../../auth/rights";
import "./sidebar.css";

const Sidebar: React.FC = () => {
  const collapsed = useAppSelector(selectSidebarCollapsed);
  const isAuthenticated = useIsAuthenticated();

  const itemSlugRouteMatch = useMatch("/items/:slug");
  const companySlugRouteMatch = useMatch("/companies/:slug");
  const organizationSlugRouteMatch = useMatch("/organizations/:slug");

  const { data: initialRights } = useGetInitialRightsByUserQuery();

  return (
    <ProSidebar collapsed={collapsed} collapsedWidth={56} width={200}>
      <SidebarHeader>
        {/**
         *  You can add a header for the sidebar ex: logo
         */}

        <Menu>
          <LinkContainer to="/">
            <MenuItem icon={<FontAwesomeIcon icon={faHome} />}>Home</MenuItem>
          </LinkContainer>
        </Menu>
      </SidebarHeader>
      <SidebarContent>
        <Menu>
          <LinkContainer to="/items/">
            <MenuItem
              icon={<FontAwesomeIcon icon={faQrcode} />}
              active={itemSlugRouteMatch !== null}
            >
              Items
            </MenuItem>
          </LinkContainer>
          <RequiresRight
            requiredRight={RIGHT_COMPANY_VIEW}
            userRights={initialRights}
          >
            <LinkContainer to="/companies">
              <MenuItem
                icon={<FontAwesomeIcon icon={faIndustry} />}
                active={companySlugRouteMatch !== null}
              >
                Companies
              </MenuItem>
            </LinkContainer>
          </RequiresRight>
          <RequiresRight
            requiredRight={RIGHT_ORGANIZATION_VIEW}
            userRights={initialRights}
          >
            <LinkContainer to="/organizations">
              <MenuItem
                icon={<FontAwesomeIcon icon={faSitemap} />}
                active={organizationSlugRouteMatch !== null}
              >
                Organizations
              </MenuItem>
            </LinkContainer>
          </RequiresRight>
          <LinkContainer to="/activity">
            <MenuItem icon={<FontAwesomeIcon icon={faClock} />}>
              Activity
            </MenuItem>
          </LinkContainer>
          <LinkContainer to="/account">
            <MenuItem icon={<FontAwesomeIcon icon={faUser} />}>
              Account
            </MenuItem>
          </LinkContainer>
          <RequiresRight
            requiredRight={RIGHT_ADMINISTRATION_VIEW}
            userRights={initialRights}
          >
            <LinkContainer to="/users">
              <MenuItem icon={<FontAwesomeIcon icon={faUserFriends} />}>
                Administration
              </MenuItem>
            </LinkContainer>
          </RequiresRight>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        {isAuthenticated && (
          <Menu>
            <MenuItem
              icon={<FontAwesomeIcon icon={faSignOutAlt} />}
              onClick={() => handleSignOut()}
            >
              Sign out
            </MenuItem>
          </Menu>
        )}
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
