import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Sort,
  Inject,
} from "@syncfusion/ej2-react-grids";
import React from "react";
import { NavLink } from "react-router-dom";
import SimpleCard from "../common/SimpleCard";
import RoleAssignment from "./interfaces/RoleAssignment";

export interface RoleAssignmentsGridProps {
  roleAssignments?: RoleAssignment[];
  isAdministrationView?: boolean;
  isLoading: boolean;
  onSelect: (roleAssignment?: RoleAssignment) => void;
}

const RoleAssignmentsGrid: React.FC<RoleAssignmentsGridProps> = (props) => {
  const handleSelectedRow = (grid: { data: RoleAssignment }) => {
    props.onSelect(grid.data);
  };

  const emailTemplate = (rowProps: any) => {
    return rowProps.user.email.replace(/^(.{2})[^@]+/, "$1***");
  };

  const inheritedTemplate = (rowProps: any) => {
    return (
      <div>
        {rowProps.organization ? (
          <div>
            <NavLink to={`../organizations/${rowProps.organization.slug}`}>
              {rowProps.organization.name}
            </NavLink>
            <span> (organization)</span>
          </div>
        ) : rowProps.company ? (
          <div>
            <NavLink to={`../companies/${rowProps.company.slug}`}>
              {rowProps.company.name}
            </NavLink>
            <span> (company)</span>
          </div>
        ) : rowProps.item ? (
          <span>{rowProps.item?.name}</span>
        ) : (
          <span className="fst-italic">System-wide role</span>
        )}
      </div>
    );
  };

  return (
    <>
      {props.roleAssignments && props.roleAssignments?.length > 0 ? (
        <GridComponent
          allowSorting={true}
          dataSource={props.roleAssignments}
          rowSelected={handleSelectedRow}
          rowDeselected={() => {
            if (props.onSelect) props.onSelect(undefined);
          }}
        >
          <ColumnsDirective>
            <ColumnDirective field="user.name" headerText="User" width="75" />
            <ColumnDirective
              template={emailTemplate}
              field="user.email"
              headerText="E-mail"
              width="75"
            />
            <ColumnDirective
              field="role.name"
              headerText="Role Name"
              width="75"
            />
            <ColumnDirective
              field="role.description"
              headerText="Role Description"
              width="100"
            />
            {!props.isAdministrationView && (
              <ColumnDirective
                template={inheritedTemplate}
                headerText="Gained through"
                width="75"
              />
            )}
          </ColumnsDirective>
          <Inject services={[Sort]} />
        </GridComponent>
      ) : (
        <SimpleCard
          displayText="No role assignments available"
          isLoading={props.isLoading}
          loadingText="Loading role assignments"
        />
      )}
    </>
  );
};

export default RoleAssignmentsGrid;
