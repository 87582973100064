import { Modal, Button } from "react-bootstrap";
import { AwesomeQRCode } from "@awesomeqr/react";
import React from "react";
import html2canvas from "html2canvas";

export interface QrCodeModalProps {
  title: string;
  show: boolean;
  itemCode: string;
  onClose: () => void;
}

const QrCodeModal: React.FC<QrCodeModalProps> = (props) => {
  const divRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleDownloadQrImage = async () => {
    const element = divRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/png");
    const link = document.createElement("a");

    link.href = data;
    link.download = `${props.title}.png`;

    link.click();
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <div
            className=""
            style={{ width: "300px", height: "300px" }}
            ref={divRef}
          >
            <AwesomeQRCode
              options={{
                text: props.itemCode,
                components: {
                  data: {
                    scale: 0.5,
                  },
                  timing: {
                    scale: 0.5,
                    protectors: false,
                  },
                  alignment: {
                    scale: 0.5,
                    protectors: false,
                  },
                  cornerAlignment: {
                    scale: 0.5,
                    protectors: true,
                  },
                },
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDownloadQrImage}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default QrCodeModal;
