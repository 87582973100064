import React, { useState } from "react";
import { toast } from "react-toastify";
import RequiresRight from "../auth/RequiresRight";
import {
  RIGHT_ROLE_ASSIGNMENT_CREATE,
  RIGHT_ROLE_ASSIGNMENT_DELETE,
} from "../auth/rights";
import DeleteButton from "../common/DeleteButton";
import Toolbar from "../common/Toolbar";
import AddRoleAssignmentButton from "./AddRoleAssignmentButton";
import RoleAssignment from "./interfaces/RoleAssignment";
import {
  useDeleteRoleAssignmentMutation,
  useGetRoleAssignmentsQuery,
} from "./api/roleAssignmentQueries";
import RoleAssignmentsGrid from "./RoleAssignmentsGrid";

export interface RoleAssignmentsViewProps {
  title: string;
  isAdministrationView?: boolean;
  userRights?: string[];
  itemId?: number;
  companyId?: number;
  organizationId?: number;
}

const RoleAssignmentsView: React.FC<RoleAssignmentsViewProps> = (props) => {
  const { data: roleAssignments, isLoading } = useGetRoleAssignmentsQuery({
    itemId: props.itemId,
    companyId: props.companyId,
    organizationId: props.organizationId,
  });

  const [deleteRoleAssignment] = useDeleteRoleAssignmentMutation();

  const [selection, setSelection] = useState<RoleAssignment>();

  const handleDeleteRoleAssignment = () => {
    if (!selection) return;

    const promise = deleteRoleAssignment(selection.id).unwrap();

    toast.promise(promise, {
      pending: `Deleting role assigment '${selection.role.name}' for user '${selection.user.name}' ...`,
      success: `Role assigment '${selection.role.name}' for user '${selection.user.name}' successfully deleted.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to delete role assigment '${selection.role.name}' for user '${selection.user.name}'.`
            : `Unable to delete role assigment '${selection.role.name}' for user '${selection.user.name}'.`;
        },
      },
    });
  };

  const HandleSelect = (selected?: RoleAssignment) => {
    setSelection(selected);
  };

  return (
    <>
      <Toolbar>
        <h1>{props.title}</h1>
        <RequiresRight
          requiredRight={RIGHT_ROLE_ASSIGNMENT_CREATE}
          userRights={props.userRights}
        >
          <AddRoleAssignmentButton
            {...props}
            currentRoleAssignments={roleAssignments}
          />
        </RequiresRight>
        <RequiresRight
          requiredRight={RIGHT_ROLE_ASSIGNMENT_DELETE}
          userRights={props.userRights}
        >
          <DeleteButton
            disabled={
              !selection ||
              // Disable the button when the selected role assignment is on a different level than the current context
              props.itemId !== selection?.item?.id ||
              props.companyId !== selection?.company?.id ||
              props.organizationId !== selection?.organization?.id ||
              (props.itemId === undefined &&
                props.companyId === undefined &&
                props.organizationId === undefined &&
                selection?.item === undefined &&
                selection?.company === undefined &&
                selection?.organization === undefined)
            }
            onDelete={handleDeleteRoleAssignment}
            type="Role Assignment"
            text={`Are you sure you want to delete the role assignment '${selection?.role.name}' for user '${selection?.user?.name}'?`}
          />
        </RequiresRight>
      </Toolbar>
      <RoleAssignmentsGrid
        roleAssignments={roleAssignments?.filter(
          (ra) =>
            props.isAdministrationView ||
            ra.organization ||
            ra.company ||
            ra.item
        )}
        isLoading={isLoading}
        onSelect={HandleSelect}
        isAdministrationView={props.isAdministrationView}
      />
    </>
  );
};

export default RoleAssignmentsView;
