import React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { Button, Card } from "react-bootstrap";
import SimpleCard from "../common/SimpleCard";
import { NavLink } from "react-router-dom";
import EntityVersion from "../entity/interfaces/EntityVersion";
import PaginatedList from "../../api/PaginatedList";
import { useNavigate } from "react-router-dom";

export interface EntitiesHistoryProps {
  entities?: PaginatedList<EntityVersion>;
  isLoading: boolean;
  showRedirect: boolean;
}

const EntitiesHistory: React.FC<EntitiesHistoryProps> = (props) => {
  const dataAvailable = props.entities !== undefined;
  const navigate = useNavigate();
  const itemLinkTemplate = (rowProps: EntityVersion) => {
    return (
      <div>
        <NavLink to={`/items/${rowProps.entity?.item?.slug}`}>
          {rowProps.entity?.item?.name}
        </NavLink>
      </div>
    );
  };

  return (
    <>
      {dataAvailable ? (
        <Card className="grid-card">
          <Card.Header
            className={
              props.showRedirect
                ? "border-0 card-header-withbutton"
                : "border-0"
            }
          >
            <span>Recently updated files</span>
            {props.showRedirect && (
              <Button
                variant="outline-primary float-end"
                onClick={() => {
                  navigate("/activity");
                }}
              >
                View all
              </Button>
            )}
          </Card.Header>
          <Card.Body className={!dataAvailable ? "" : "p-0"}>
            <GridComponent
              dataSource={props.entities?.data}
              className="border-0"
            >
              <Inject services={[Resize]} />

              <ColumnsDirective>
                <ColumnDirective
                  headerText="Item"
                  template={itemLinkTemplate}
                />
                <ColumnDirective
                  field="entity.displayName"
                  headerText="File name"
                />
                <ColumnDirective
                  autoFit
                  field="versionCount"
                  headerText="Versions"
                />
                <ColumnDirective
                  autoFit
                  field="user.name"
                  headerText="Updated by"
                />
                <ColumnDirective
                  autoFit
                  field="timestampZone"
                  headerText="Updated at"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                />
              </ColumnsDirective>
            </GridComponent>
          </Card.Body>
        </Card>
      ) : (
        <SimpleCard
          headerText="Recently updated files"
          loadingText="Loading recent files..."
          displayText="No files available"
          isLoading={props.isLoading}
        />
      )}
    </>
  );
};

export default EntitiesHistory;
