import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddOrganizationModal from "./AddOrganizationModal";
import CreateOrganization from "../interfaces/CreateOrganization";
import Toolbar from "../../common/Toolbar";
import { toast } from "react-toastify";
import { useGetInitialRightsByUserQuery } from "../../auth/authorizationQueries";
import RequiresRight from "../../auth/RequiresRight";
import { RIGHT_ORGANIZATION_CREATE } from "../../auth/rights";
import { usePostOrganizationMutation } from "../api/organizationQueries";

const OrganizationsToolbar: React.FC = () => {
  const [modalShown, setModalShown] = useState(false);

  const { data: initialRights } = useGetInitialRightsByUserQuery();
  const [postOrganization] = usePostOrganizationMutation();

  const handleSubmit = (organization: CreateOrganization) => {
    const createPromise = postOrganization(organization).unwrap();
    setModalShown(false);

    toast.promise(createPromise, {
      pending: `Creating new organization '${organization.name}' ...`,
      success: `New organization '${organization.name}' created.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to create new organization '${organization.name}'.`
            : `Unable to create new organization '${organization.name}'.`;
        },
      },
    });
  };

  return (
    <>
      <Toolbar>
        <h1>Organizations</h1>
        <RequiresRight
          requiredRight={RIGHT_ORGANIZATION_CREATE}
          userRights={initialRights}
        >
          <Button variant="primary" onClick={() => setModalShown(true)}>
            {`Add Organization`}
          </Button>
        </RequiresRight>
      </Toolbar>
      <AddOrganizationModal
        show={modalShown}
        onCancel={() => setModalShown(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default OrganizationsToolbar;
