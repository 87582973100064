import React from "react";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <div className="jumbotron">
      <h1>404</h1>
      <p>Specified page can not be found.</p>
      <Link to="/" className="btn btn-primary">
        Take me home
      </Link>
    </div>
  );
}

export default HomePage;
