import {
  SelectedEventArgs,
  UploaderComponent,
} from "@syncfusion/ej2-react-inputs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useAppSelector } from "../../../app/hooks";
import { toBase64 } from "../../common/Utilities/Utils";
import CreateEntity from "../../entity/interfaces/CreateEntity";
import { FileType } from "../../entity/interfaces/FileType";
import { selectSelectedEntityFolder } from "../../entity/entitySlice";
import { useGetEntitiesByItemIdQuery } from "../qrItemQueries";
import ModalFileTree from "./ModalFileTree";

export interface AddFileModalProps {
  show: boolean;
  itemId: number;
  onSubmit: (link: CreateEntity) => void;
  onCancel: () => void;
}

const AddFileModal: React.FC<AddFileModalProps> = (props) => {
  const { data: entities } = useGetEntitiesByItemIdQuery(props.itemId);
  const selectedFolderId = useAppSelector(selectSelectedEntityFolder(entities));
  const [selectedFolder, setSelectedFolder] = useState(selectedFolderId);

  const uploader = useRef() as React.MutableRefObject<UploaderComponent>;
  const [fileCount, setFileCount] = useState(-1);

  // Use a memo for the file tree to avoid re-rendering on state change when selecting a new folder.
  // Otherwise, the tree will flicker back to the original entity selection.
  const fileTree = useMemo(
    () => (
      <ModalFileTree
        itemId={props.itemId}
        onNodeSelected={(id: number) => setSelectedFolder(id)}
      />
    ),
    [props.itemId, entities]
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (!uploader.current) return;

    const files = uploader.current.getFilesData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName = file.name;
      const displayName = fileName.substring(0, fileName.lastIndexOf("."));

      const newFile: CreateEntity = {
        fileName,
        displayName,
        fileType: FileType.File,
        itemId: props.itemId,
        entityId: selectedFolder === -1 ? undefined : selectedFolder,
        content: await toBase64(file.rawFile as File),
      };

      props.onSubmit(newFile);
    }

    setFileCount(files.length);
  };

  const handleSelected = (args: SelectedEventArgs) => {
    if (!uploader.current) return;

    setFileCount(uploader.current.filesData.length + args.filesData.length);
  };

  return (
    <Modal show={props.show} centered onExited={() => setFileCount(-1)}>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header>Add File</Modal.Header>
        <Modal.Body>
          {fileTree}

          <Form.Group className="mb-3">
            <Form.Label>File</Form.Label>
            <UploaderComponent
              ref={uploader}
              autoUpload={false}
              selected={handleSelected}
              maxFileSize={1.5e8} // Max size in bytes, 1.5e8 is 150 Megabytes
            />
            {/* Custom feedback here, because the 'Change' event of the UploaderComponent does not execute */}
            <div
              className="invalid-feedback"
              style={{
                display: fileCount === 0 ? "inline-block" : "none",
              }}
            >
              Please upload a file.
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={fileCount <= 0}>
            {fileCount > 1 ? "Add Files" : "Add File"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddFileModal;
