import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import ConfirmModal from "../common/modals/ConfirmModal";

export interface DeleteButtonProps {
  type: string;
  text: string;
  disabled: boolean;
  onDelete: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
  const refConfirmModal = useRef<ConfirmModal>(null);

  return (
    <>
      <Button
        variant="danger"
        disabled={props.disabled}
        onClick={() => refConfirmModal.current?.open()}
      >
        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
        {` Delete`}
      </Button>
      <ConfirmModal
        ref={refConfirmModal}
        header={`Delete ${props.type}`}
        text={props.text}
        confirmText={`Delete ${props.type}`}
        onConfirm={props.onDelete}
      />
    </>
  );
};

export default DeleteButton;
