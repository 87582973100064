import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import TemplateFolder from "../interfaces/TemplateFolder";
import { NodeModel, Tree } from "@minoru/react-dnd-treeview";
import nextId from "react-id-generator";
import TemplateTreeNode from "./TemplateTreeNode";

export interface TemplateTreeProps {
  folders: TemplateFolder[];
  onChange: (template: TemplateFolder[]) => void;
  onNodeSelected: (folder: TemplateFolder) => void;
  allowEdit: boolean;
}

const TemplateTree: React.FC<TemplateTreeProps> = (props) => {
  const [treeNodes, setTreeNodes] = useState<NodeModel<TemplateFolder>[]>();
  const [selectedNode, setSelectedNode] = useState<
    TemplateFolder | undefined
  >();

  useEffect(() => {
    const foldersToDisplay = props.folders.map((folder) => {
      const nodeModel: NodeModel<TemplateFolder> = {
        id: nextId("folder_"),
        parent: 0,
        text: folder.displayText,
        droppable: false,
        data: folder,
      };

      return nodeModel;
    });

    console.log(foldersToDisplay);
    setTreeNodes(foldersToDisplay);
  }, [selectedNode, props.folders]);

  const onNodeTextChanged = (
    node: NodeModel<TemplateFolder>,
    text: string
  ): boolean => {
    if (!node.data || !treeNodes) return false;
    if (text.trim() === "") return false;
    if (props.folders.some((folder) => folder.displayText === text))
      return false;

    const updatedFolders = treeNodes
      .filter((n) => n.id !== node.id && n.data)
      .map((n) => n.data!);
    var updatedFolder: TemplateFolder = { ...node.data, displayText: text };
    updatedFolders?.push(updatedFolder);

    props.onChange(updatedFolders);
    return true;
  };

  if (!treeNodes) return <></>;

  return (
    <Card>
      <Card.Header>
        <Card.Title>Item Template</Card.Title>
        <Card.Text className="text-muted fs-6 fw-light fst-italic">
          Define a default folder structure for newly created QR Items in this
          organization.
        </Card.Text>
      </Card.Header>
      <Card.Body>
        <Tree
          tree={treeNodes}
          rootId={0}
          classes={{
            root: "tree-root",
            draggingSource: "dragging-source",
            dropTarget: "drop-target",
          }}
          sort={(
            a: NodeModel<TemplateFolder>,
            b: NodeModel<TemplateFolder>
          ) => {
            return a.text.localeCompare(b.text);
          }}
          render={(
            node: NodeModel<TemplateFolder>,
            { depth, isOpen, onToggle }
          ) => (
            <TemplateTreeNode
              node={node}
              depth={depth}
              isSelected={node.text === selectedNode?.displayText}
              allowEdit={props.allowEdit}
              onSelect={(args) => {
                if (!node.data) return;
                setSelectedNode(node.data);
                props.onNodeSelected(node.data);
              }}
              onNodeTextChanged={onNodeTextChanged}
            />
          )}
          canDrag={(args) => false}
          onDrop={(args) => {}}
          initialOpen={true}
        />
      </Card.Body>
    </Card>
  );
};

export default TemplateTree;
