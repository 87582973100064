import moment from "moment";
import { Modal, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import QrItemFileEntityDisplay from "./QrItemFileEntityDisplay";
import EntityVersion from "../../entity/interfaces/EntityVersion";
import Entity from "../../entity/interfaces/Entity";
import FileContent from "../../common/interfaces/FileContent";
import { useGetEntityContentQuery } from "../../entity/entityQueries";

export interface FileDisplayModalProps {
  entity: Entity;
  entityVersion?: EntityVersion;
  show: boolean;
  onClose: () => void;
}

const FileDisplayModal: React.FC<FileDisplayModalProps> = (props) => {
  const { data: entityContent, isFetching } = useGetEntityContentQuery(
    { entityId: props.entity.id, versionId: props.entityVersion?.versionId },
    {
      skip: !props.show || props.entity === undefined,
    }
  );

  async function downloadFile(
    entity?: Entity,
    entityContent?: FileContent | null
  ) {
    if (!entityContent || entityContent === null || !entity) return;
    if (!entity.fileName) return;

    const element = document.createElement("a");
    element.href = `data:${entityContent.mimeType};base64,${entityContent.content}`;
    element.download = entity.fileName;
    document.body.appendChild(element);
    element.click();

    toast.success(`'${entity.displayName}' downloaded.`);
  }

  return (
    <>
      <Modal show={props.show} onHide={props.onClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.entity?.displayName}{" "}
            {props.entityVersion &&
              ` (version ${moment(props.entityVersion.timestampZone).format(
                "YYYY-MM-DD HH:mm:ss"
              )})`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="file-preview">
            {isFetching ? (
              <Spinner
                className="position-absolute top-50 start-50"
                animation="border"
                role="status"
              />
            ) : (
              <QrItemFileEntityDisplay
                entityContent={entityContent}
                fileType={props.entity?.fileType}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={!entityContent || entityContent === null}
            onClick={() => downloadFile(props.entity, entityContent)}
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FileDisplayModal;
