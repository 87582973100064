import { baseApi } from "../../common/baseApi";
import CreateItemTemplate from "../interfaces/CreateItemTemplate";
import ItemTemplate from "../interfaces/ItemTemplate";

export const itemTemplateQueries = baseApi
  .enhanceEndpoints({ addTagTypes: ["ItemTemplates"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      deleteItemTemplate: builder.mutation<void, number>({
        query: (id) => ({
          url: `itemtemplates/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["ItemTemplates"],
      }),
      getItemTemplateByOrganizationId: builder.query<ItemTemplate, number>({
        query: (organizationId) =>
          `itemtemplates/organization/${organizationId}`,
        providesTags: ["ItemTemplates"],
      }),
      postItemTemplate: builder.mutation<ItemTemplate, CreateItemTemplate>({
        query: (body) => ({
          url: `itemtemplates`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["ItemTemplates"],
      }),
      putItemTemplate: builder.mutation<ItemTemplate, ItemTemplate>({
        query: (body) => ({
          url: `itemtemplates/${body.id}`,
          method: "PUT",
          body,
        }),
        invalidatesTags: (result) => [
          { type: "ItemTemplates", id: result?.id },
        ],
      }),
    }),
  });

export const {
  useDeleteItemTemplateMutation,
  useGetItemTemplateByOrganizationIdQuery,
  usePostItemTemplateMutation,
  usePutItemTemplateMutation,
} = itemTemplateQueries;
