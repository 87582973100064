import OrganizationMetric from "../interfaces/OrganizationMetric";
import { baseApi } from "../../common/baseApi";
import Company from "../../company/interfaces/Company";
import Organization from "../interfaces/Organization";
import CreateOrganization from "../interfaces/CreateOrganization";

export const organizationQueries = baseApi
  .enhanceEndpoints({ addTagTypes: ["Organizations", "OrganizationMetrics"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Get all organizations.
      getOrganizations: builder.query<Organization[], void>({
        query: () => `organizations`,
        providesTags: ["Organizations"],
      }),

      // Get the organization metrics by organization id.
      getOrganizationMetrics: builder.query<OrganizationMetric[], number>({
        query: (id) => `organizations/${id}/metrics`,
        providesTags: (_result, _error, id) => [
          { type: "OrganizationMetrics", id },
        ],
      }),

      // Get the organization's companies by organization id.
      getOrganizationCompanies: builder.query<Company[], number>({
        query: (id) => `organizations/${id}/companies`,
        providesTags: (_result, _error, id) => [
          { type: "OrganizationMetrics", id },
        ],
      }),

      // Get the organization by its slug.
      getOrganizationBySlug: builder.query<Organization, string>({
        query: (slug) => `organizations/slug/${slug}`,
        providesTags: (result) => [{ type: "Organizations", id: result?.id }],
      }),

      // Update an organization.
      putOrganization: builder.mutation<Organization, Organization>({
        query: (body) => ({
          url: `organizations`,
          method: "PUT",
          body,
        }),
        invalidatesTags: (result) => [
          { type: "Organizations", id: result?.id },
        ],
      }),

      // Add a new organization.
      postOrganization: builder.mutation<Organization, CreateOrganization>({
        query: (body) => ({
          url: `organizations`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["Organizations"],
      }),

      // Delete an organization.
      deleteOrganization: builder.mutation<Organization, number>({
        query: (id) => ({
          url: `organizations/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Organizations"],
      }),
    }),
  });

export const {
  useGetOrganizationsQuery,
  useGetOrganizationMetricsQuery,
  useGetOrganizationCompaniesQuery,
  useGetOrganizationBySlugQuery,
  usePutOrganizationMutation,
  usePostOrganizationMutation,
  useDeleteOrganizationMutation,
} = organizationQueries;
