import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Inject,
  RowSelectEventArgs,
} from "@syncfusion/ej2-react-grids";

import React from "react";
import SimpleCard from "../../common/SimpleCard";
import Company from "../interfaces/Company";

export interface CompaniesGridProps {
  dataSource?: Company[];
  onSelect: (company: Company) => void;
  isLoading: boolean;
}

const CompaniesGrid: React.FC<CompaniesGridProps> = (props) => {
  const dataAvailable = props.dataSource && props.dataSource.length > 0;

  const handleSelect = (args: RowSelectEventArgs) => {
    props.onSelect(args.data as Company);
  };

  return (
    <>
      {dataAvailable ? (
        <GridComponent dataSource={props.dataSource} rowSelected={handleSelect}>
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Name" />
            <ColumnDirective
              field="organization.name"
              headerText="Organization"
            />
          </ColumnsDirective>
          <Inject services={[CommandColumn]} />
        </GridComponent>
      ) : (
        <SimpleCard
          loadingText="Loading companies..."
          displayText="No companies available"
          isLoading={props.isLoading}
        />
      )}
    </>
  );
};

export default CompaniesGrid;
