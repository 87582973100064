import Company from "./interfaces/Company";
import CreateCompany from "./interfaces/CreateCompany";
import FileContent from "../common/interfaces/FileContent";
import { organizationQueries } from "../organization/api/organizationQueries";
import SetCompanyImage from "../common/interfaces/SetCompanyImage";

export const companyQueries = organizationQueries
  .enhanceEndpoints({ addTagTypes: ["Companies"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCompanies: builder.query<Company[], void>({
        query: () => `companies`,
        providesTags: ["Companies"],
      }),
      getCompanyBySlug: builder.query<Company, string>({
        query: (slug) => `companies/slug/${slug}`,
        providesTags: (result) => [{ type: "Companies", id: result?.id }],
      }),
      getCompanyImage: builder.query<FileContent, number>({
        query: (companyId) => `companies/${companyId}/image`,
        providesTags: (_result, _error, id) => [{ type: "Companies", id }],
      }),
      postCompanyImage: builder.mutation<void, SetCompanyImage>({
        query: (data) => ({
          url: `companies/${data.companyId}/image`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: (_result, _error, args) => [
          { type: "Companies", id: args.companyId },
        ],
      }),
      putCompany: builder.mutation<Company, Company>({
        query: (body) => ({
          url: `companies`,
          method: "PUT",
          body,
        }),
        invalidatesTags: (result) => [
          { type: "Companies" },
          { type: "Organizations", id: result?.organization?.id },
        ],
      }),
      postCompany: builder.mutation<Company, CreateCompany>({
        query: (body) => ({
          url: `companies`,
          method: "POST",
          body,
        }),
        invalidatesTags: (result) => ["Companies"],
      }),
      deleteCompany: builder.mutation<Company, number>({
        query: (id) => ({
          url: `companies/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result) => [
          "Companies",
          { type: "OrganizationMetrics", id: result?.organization.id },
        ],
      }),
    }),
  });

export const {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useGetCompanyBySlugQuery,
  usePutCompanyMutation,
  useGetCompanyImageQuery,
  usePostCompanyImageMutation,
  usePostCompanyMutation,
  useDeleteCompanyMutation,
} = companyQueries;
