import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useGetAdministrationRightsQuery } from "../auth/authorizationQueries";
import RoleAssignmentsView from "../role-assignment/RoleAssignmentsView";

const AdministrationPage: React.FC = () => {
  const { data: systemRights } = useGetAdministrationRightsQuery();

  return (
    <Tabs
      defaultActiveKey="role-assignments"
      className="mb-3"
      variant="tabs"
      hidden={true}
    >
      <Tab title="Role Assignments" eventKey="role-assignments">
        <RoleAssignmentsView
          title="Role Assignments"
          userRights={systemRights}
          isAdministrationView={true}
        />
      </Tab>
    </Tabs>
  );
};

export default AdministrationPage;
