import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab, Spinner } from "react-bootstrap";
import { useAppDispatch } from "../../../app/hooks";
import QrItemHeader from "./QrItemHeader";
import QrItemFiles from "../../entity/QrItemFiles";
import QrItemToolbar from "./QrItemToolbar";
import { toast } from "react-toastify";
import { RIGHT_USERS_VIEW } from "../../auth/rights";
import checkRight from "../../auth/checkRight";
import RoleAssignmentsView from "../../role-assignment/RoleAssignmentsView";
import {
  useGetEntitiesByItemIdQuery,
  useGetItemBySlugQuery,
} from "../qrItemQueries";

const QrItemPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const { data: item, isLoading } = useGetItemBySlugQuery(slug ? slug : "", {
    skip: slug === undefined,
  });
  const {
    data: entities,
    isError,
    error,
    isFetching: isLoadingEntities,
  } = useGetEntitiesByItemIdQuery(item ? item.id : -1, {
    skip: item === undefined,
  });

  useEffect(() => {
    if (!isError) return;

    debugger;

    const err = error as any;
    toast.error(
      err.status === 401
        ? `Not allowed to load files for '${item?.name}'.`
        : `Unable to load files for '${item?.name}'.`
    );
  }, [dispatch, error, isError]);

  if (isLoading)
    return (
      <Spinner
        animation="border"
        role="status"
        className="position-absolute top-50 start-50"
      />
    );
  if (!item) return <h4>No item selected</h4>;

  return (
    <Tabs defaultActiveKey="details" className="mb-3" variant="tabs">
      <Tab eventKey="details" title="Details">
        <QrItemToolbar item={item} />
        <QrItemHeader item={item} />
        <QrItemFiles
          itemId={item.id}
          entities={entities}
          isLoading={isLoadingEntities}
          userRights={item.rights}
        />
      </Tab>
      {checkRight({
        requiredRight: RIGHT_USERS_VIEW,
        userRights: item.rights,
      }) && (
        <Tab eventKey="role-assignments" title="Role Assignments">
          <RoleAssignmentsView
            title={item.name}
            itemId={item.id}
            userRights={item.rights}
          />
        </Tab>
      )}
    </Tabs>
  );
};

export default QrItemPage;
