import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NodeModel } from "@minoru/react-dnd-treeview";
import "../../common/tree/treeNode.css";
import "./templateTreeNode.css";
import TemplateFolder from "../interfaces/TemplateFolder";
import { useState } from "react";

interface TemplateTreeNodeProps {
  node: NodeModel<TemplateFolder>;
  depth: number;
  isSelected: boolean;
  allowEdit: boolean;
  onSelect: (node: NodeModel) => void;
  onNodeTextChanged: (node: NodeModel<TemplateFolder>, text: string) => boolean;
}

const TemplateTreeNode: React.FC<TemplateTreeNodeProps> = (props) => {
  const [nodeText, setNodeText] = useState<string>(props.node.text);
  const indent = props.depth * 25;

  if (!props.node.data) return <></>;

  return (
    <div
      className={`tree-node root ${props.isSelected ? "selected" : ""}`}
      style={{ paddingInlineStart: indent }}
    >
      <div
        className="tree-node-data"
        onMouseDown={() => props.onSelect(props.node)}
      >
        <div className="node-type-icon">
          <FontAwesomeIcon icon={faFolder} className="align-self-center me-2" />
        </div>
        <div className="node-text">
          <input
            type="text"
            defaultValue={props.node.text}
            value={nodeText}
            disabled={!props.allowEdit}
            onChange={(args) => setNodeText(args.target.value)}
            onBlur={() => {
              if (!props.node.data) return;
              if (nodeText === props.node.text) return;
              if (!props.onNodeTextChanged(props.node, nodeText))
                setNodeText(props.node.text); // Reset the name if the text couldn't be changed.
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateTreeNode;
