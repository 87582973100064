import { baseApi } from "../common/baseApi";

export const authorizationQueries = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInitialRightsByUser: builder.query<string[], void>({
      query: () => `authorization/initial-rights`,
    }),
    getAdministrationRights: builder.query<string[], void>({
      query: () => `authorization/administration-rights`,
    }),
  }),
});

export const {
  useGetInitialRightsByUserQuery,
  useGetAdministrationRightsQuery,
} = authorizationQueries;
