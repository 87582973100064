const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SignUpSignIn",
    forgotPassword: "B2C_1_ResetPassword",
    editProfile: "B2C_1_ProfileEditing",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://VDEQRService.b2clogin.com/VDEQRService.onmicrosoft.com/B2C_1_SignUpSignIn",
    },
    forgotPassword: {
      authority:
        "https://VDEQRService.b2clogin.com/VDEQRService.onmicrosoft.com/B2C_1_ResetPassword",
    },
    editProfile: {
      authority:
        "https://VDEQRService.b2clogin.com/VDEQRService.onmicrosoft.com/B2C_1_ProfileEditing",
    },
  },
  authorityDomain: "VDEQRService.b2clogin.com",
};

export const msalConfig = {
  auth: {
    clientId: "9e4214b7-7bb2-404c-a664-cd2555dfbf11",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_WEBSITE_URL,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const tokenRequest = {
  scopes: [
    "https://VDEQRService.onmicrosoft.com/315dc3d2-29da-4fb2-ae32-9da368ed7e84/QrItemsRead",
  ],
};
