import React, { useEffect } from "react";
import Company from "../interfaces/Company";
import CompaniesToolbar from "./CompaniesToolbar";
import CompaniesGrid from "./CompaniesGrid";
import { useNavigate } from "react-router-dom";
import { useGetCompaniesQuery } from "../companyQueries";
import { toast } from "react-toastify";

const CompanyOverview: React.FC = () => {
  const { data: companies, isLoading, error } = useGetCompaniesQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      const _error: any = error;
      toast.error(
        _error?.status === 401
          ? "Not allowed to view companies"
          : "Unable to get companies"
      );
    }
  }, [error]);

  const handleSelect = (company?: Company) => {
    navigate(`/companies/${company?.slug}`);
  };

  return (
    <>
      <CompaniesToolbar />
      <CompaniesGrid
        dataSource={companies}
        isLoading={isLoading}
        onSelect={handleSelect}
      />
    </>
  );
};

export default CompanyOverview;
