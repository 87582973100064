import React, { useRef } from "react";
import { Tabs, Tab, Spinner, Button, Form } from "react-bootstrap";
import Toolbar from "../../common/Toolbar";
import DeleteButton from "../../common/DeleteButton";
import { useParams, useNavigate } from "react-router-dom";
import EditableHeader from "../../common/EditableHeader";
import { toast } from "react-toastify";
import CompanyCard from "./CompanyCard";
import { toFileContent } from "../../common/Utilities/Utils";
import {
  RIGHT_COMPANY_DELETE,
  RIGHT_COMPANY_UPDATE,
  RIGHT_USERS_VIEW,
} from "../../auth/rights";
import RequiresRight from "../../auth/RequiresRight";
import checkRight from "../../auth/checkRight";
import ConfirmModal from "../../common/modals/ConfirmModal";
import {
  useDeleteCompanyMutation,
  useGetCompanyBySlugQuery,
  useGetCompanyImageQuery,
  usePostCompanyImageMutation,
  usePutCompanyMutation,
} from "../companyQueries";
import RoleAssignmentsView from "../../role-assignment/RoleAssignmentsView";
import SetCompanyImage from "../../common/interfaces/SetCompanyImage";
import EmptyItemsList from "../../qr-item/empty/EmptyItemsList";

const CompanyDetails: React.FC = (props) => {
  const navigate = useNavigate();

  const { slug } = useParams();

  if (!slug) {
    throw new Error("Slug must be defined.");
  }

  const { data: company, isLoading } = useGetCompanyBySlugQuery(slug);
  // Workaround: there should always be a company before using this query,
  // But we cannot execute a hook conditionally.
  const { data: image } = useGetCompanyImageQuery(company ? company.id : -1, {
    skip: !company,
  });

  const [putCompany] = usePutCompanyMutation();
  const [putCompanyImage] = usePostCompanyImageMutation();
  const [deleteCompany] = useDeleteCompanyMutation();

  const refConfirmModal = useRef<ConfirmModal>(null);
  const refFileInput = useRef<HTMLInputElement>(null);

  if (isLoading)
    return (
      <Spinner
        animation="border"
        role="status"
        className="position-absolute top-50 start-50"
      />
    );
  if (!company) return <h4>No company selected.</h4>;

  const handleDeleteCompany = () => {
    if (!company) return;

    const promise = deleteCompany(company.id)
      .unwrap()
      .then(() => navigate("../companies"));
    toast.promise(promise, {
      pending: `Deleting company '${company.name}' and its items ...`,
      success: `${company.name} successfully deleted.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to delete company '${company.name?.toLowerCase()}'.`
            : `Unable to delete company '${company.name?.toLowerCase()}'.`;
        },
      },
    });
  };

  const handleCompanyNameChange = (name: string) => {
    const updatedCompany = { ...company, name };

    const updatePromise = putCompany(updatedCompany)
      .unwrap()
      .then((response) => {
        navigate(`/companies/${response.slug}`, { replace: true });
      });

    toast.promise(updatePromise, {
      pending: `Updating company '${name}' ...`,
      success: `company '${name}' updated.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to update company '${name}'.`
            : `Unable to update company '${name}'.`;
        },
      },
    });
  };

  const handleBannerUpload = async (file: File | undefined) => {
    const uploadedImage = file ? await toFileContent(file) : undefined;

    const setCompanyImage: SetCompanyImage = {
      companyId: company.id,
      fileContent: uploadedImage,
    };

    const promise = putCompanyImage(setCompanyImage).unwrap();

    toast.promise(promise, {
      pending: `Updating company image ...`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to update company image.`
            : `Unable to update company image.`;
        },
      },
    });
  };

  return (
    <Tabs defaultActiveKey="details" className="mb-3" variant="tabs">
      <Tab eventKey="details" title="Details">
        <Toolbar>
          <EditableHeader
            requiredRight={RIGHT_COMPANY_UPDATE}
            userRights={company.rights}
            text={company.name}
            onSubmit={handleCompanyNameChange}
          />
          <Form.Control
            ref={refFileInput}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (
                event.currentTarget.files &&
                event.currentTarget.files.length === 1
              ) {
                handleBannerUpload(event.currentTarget.files[0]);
              }
            }}
          />
          <RequiresRight
            requiredRight={RIGHT_COMPANY_UPDATE}
            userRights={company.rights}
          >
            <Button
              variant="outline-primary"
              className="float-end"
              onClick={() => refFileInput.current?.click()}
            >
              Change image
            </Button>
            {image && (
              <Button
                variant="outline-danger"
                className="float-end"
                onClick={() => refConfirmModal.current?.open()}
              >
                Remove image
              </Button>
            )}
          </RequiresRight>
          <ConfirmModal
            ref={refConfirmModal}
            header={`Remove banner image`}
            text={`Are you sure you want to remove the company banner image?`}
            confirmText={`Remove banner image`}
            onConfirm={() => handleBannerUpload(undefined)}
          />
          <RequiresRight
            requiredRight={RIGHT_COMPANY_DELETE}
            userRights={company.rights}
          >
            <DeleteButton
              disabled={!company}
              type="Company"
              text={`Are you sure you want to delete '${company?.name}' and its items?`}
              onDelete={handleDeleteCompany}
            />
          </RequiresRight>
        </Toolbar>
        <h3>Item preview</h3>
        <CompanyCard
          company={company}
          image={image}
          onClick={handleBannerUpload}
        />
      </Tab>
      <Tab eventKey="empty-items" title="Empty items">
        <h1>{company.name}</h1>
        <p>The following items contain no files:</p>
        <EmptyItemsList
          filters={[`CompanyId==${company ? company.id : -1}`]}
        />
      </Tab>
      {checkRight({
        requiredRight: RIGHT_USERS_VIEW,
        userRights: company.rights,
      }) && (
        <Tab eventKey="role-assignments" title="Role Assignments">
          <RoleAssignmentsView
            title={company.name}
            userRights={company.rights}
            companyId={company.id}
          />
        </Tab>
      )}
    </Tabs>
  );
};

export default CompanyDetails;
