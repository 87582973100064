import { Formik, Form as FormikForm } from "formik";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import CreateOrganization from "../interfaces/CreateOrganization";
import * as yup from "yup";

export interface AddOrganizationModalProps {
  show: boolean;
  onSubmit: (organization: CreateOrganization) => void;
  onCancel: () => void;
}

const AddOrganizationModal: React.FC<AddOrganizationModalProps> = (props) => {
  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <Modal show={props.show} centered>
      <Formik
        initialValues={{ name: "" }}
        validationSchema={yup.object({
          name: yup.string().required("Please enter a valid name"),
        })}
        onSubmit={(values: CreateOrganization, { setSubmitting }) => {
          props.onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleChange, values, errors, touched, dirty, isValid }) => (
          <FormikForm noValidate>
            <Modal.Header>Add Organization</Modal.Header>
            <Modal.Body>
              <Form.Label>Organization Name</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  required
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={!dirty || !isValid}>
                Add organization
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddOrganizationModal;
