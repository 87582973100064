import React, { useState } from "react";
import { Row, Col, FormControl, Container } from "react-bootstrap";
import PageSizeSelector from "../common/pagination/PageSizeSelector";
import Paginator from "../common/pagination/Paginator";
import Sorter from "../common/sorting/Sorter";
import Toolbar from "../common/Toolbar";
import EntitiesHistory from "../home/EntitiesHistory";
import { useGetRecentlyUpdatedEntitiesQuery } from "./activityApi";

const ActivityPage: React.FC = () => {
  const [filterText, setFilterText] = useState<string>("");
  const [sortProperty, setSortProperty] = useState<string>("-Timestamp");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(15);

  const sortOptions = [
    { value: "-Timestamp", description: "Updated at (descending)" },
    { value: "Timestamp", description: "Updated at (ascending)" },
    { value: "Entity.DisplayName", description: "File name (ascending)" },
    { value: "-Entity.DisplayName", description: "File name (descending)" },
    { value: "Entity.Item.Name", description: "Item name (ascending)" },
    { value: "-Entity.Item.Name", description: "Item name (descending)" },
    { value: "User.Name", description: "User name (ascending)" },
    { value: "-User.Name", description: "User name (descending)" },
  ];

  const { data: entities, isLoading } = useGetRecentlyUpdatedEntitiesQuery({
    page: pageNumber,
    pageSize: itemsPerPage,
    sorts: [sortProperty],
    filters: [`(Entity.Item.Name|Entity.DisplayName|User.Name)@=${filterText}`],
  });

  function handleKeyPress(event: any) {
    if (event.key !== "Enter") {
      return;
    }

    setFilterText(event.target.value);
  }

  return (
    <>
      <Toolbar>
        <h1>Activity</h1>
      </Toolbar>

      <Container fluid={true}>
        <Row className="mb-3">
          <Col>
            <FormControl
              className="mb-1"
              id="filter"
              name="filter"
              placeholder="Type and press enter to filter recently updated files..."
              aria-label="Filter"
              onKeyPress={handleKeyPress}
            />
          </Col>
          <Col md lg="2">
            <Sorter
              sortOption={sortProperty}
              sortOptions={sortOptions}
              setSortOption={(option) => setSortProperty(option)}
            />
          </Col>
          <Col md lg="2">
            <PageSizeSelector
              itemsPerPage={itemsPerPage}
              pageSizes={[15, 30, 60, 120]}
              setPageSize={(pageSize) => setItemsPerPage(pageSize)}
            />
          </Col>
        </Row>
      
        <EntitiesHistory entities={entities} isLoading={isLoading} showRedirect={false} />

        {!entities || (entities.totalCount === 0) ? "" : (
          <Paginator
            currentPage={pageNumber}
            totalItems={entities?.totalCount}
            itemsPerPage={itemsPerPage}
            setPageNumber={(pageNumber) => setPageNumber(pageNumber)}
            setPageSize={(pageSize) => setItemsPerPage(pageSize)}
          />
        )}
      </Container>
    </>
  );
};

export default ActivityPage;