import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch } from "../../../app/hooks";
import { setSelectedEntity } from "../entitySlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faLink,
  faSave as faFloppy,
  faSquareArrowUpRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import RequiresRight from "../../auth/RequiresRight";
import { RIGHT_ENTITY_DELETE, RIGHT_ENTITY_UPDATE } from "../../auth/rights";
import Entity from "../interfaces/Entity";
import { FileType } from "../interfaces/FileType";
import ConfirmModal from "../../common/modals/ConfirmModal";
import { useLazyGetItemByIdQuery } from "../../qr-item/qrItemQueries";
import {
  useDeleteEntityMutation,
  useLazyGetEntityContentQuery,
} from "../entityQueries";
import { downloadFile } from "../../common/Utilities/Utils";
import { useNavigate } from "react-router-dom";
import FileDisplayModal from "../../qr-item/modals/FileDisplayModal";

export interface QrItemEntityToolbarProps {
  entity: Entity;
  userRights?: string[];
  formDirty: boolean;
}

const QrItemEntityToolbar: React.FC<QrItemEntityToolbarProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const refConfirmModal = useRef<ConfirmModal>(null);
  const [previewShow, setPreviewShow] = useState<boolean>(false);

  const [getItem] = useLazyGetItemByIdQuery();

  const [getEntityContent] = useLazyGetEntityContentQuery();
  const [deleteEntity] = useDeleteEntityMutation();

  const downloadEntityContent = async (entity: Entity) => {
    if (entity.fileType === FileType.Folder) return;
    if (!entity.fileName) return;

    const downloadPromise = getEntityContent({ entityId: entity.id })
      .unwrap()
      .then((content) => {
        if (entity.fileName) downloadFile(content, entity.fileName);
      });

    toast.promise(downloadPromise, {
      pending: `Downloading '${entity.displayName}' ...`,
      success: `'${entity.displayName}' downloaded.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to download '${entity.displayName}'.`
            : `Unable to download '${entity.displayName}'.`;
        },
      },
    });
  };

  const openWeblink = (entity: Entity) => {
    if (entity.fileType !== FileType.Weblink) return;

    window.open(entity.context, "_blank");
  };

  const openLinkedItem = async (entity: Entity) => {
    if (entity.fileType !== FileType.Item) return;
    if (!entity.context || isNaN(+entity.context)) return;

    const item = (await getItem(Number(entity.context))).data;

    if (!item) {
      toast.error(`Unable to open linked item '${entity.displayName}'.`);
      return;
    }

    dispatch(setSelectedEntity(undefined));
    navigate(`/items/${item.slug}`);
  };

  return (
    <>
      <div className="d-flex flex-xl-row flex-sm-column justify-content-end mb-4">
        {props.entity.fileType === FileType.File && (
          <Button
            className="mb-1 me-1"
            variant="outline-secondary"
            onClick={() => downloadEntityContent(props.entity)}
          >
            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
            {` Download`}
          </Button>
        )}
        {props.entity.fileType === FileType.Weblink && (
          <Button
            className="mb-1 me-1"
            variant="outline-secondary"
            onClick={() => openWeblink(props.entity)}
          >
            <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
            {` Open weblink`}
          </Button>
        )}
        {props.entity.fileType === FileType.Item && (
          <Button
            className="mb-1 me-1"
            variant="outline-secondary"
            onClick={() => openLinkedItem(props.entity)}
          >
            <FontAwesomeIcon icon={faSquareArrowUpRight}></FontAwesomeIcon>
            {` Open linked item`}
          </Button>
        )}
        {(props.entity.mimeType?.includes("image") ||
          props.entity?.mimeType?.includes("pdf") ||
          props.entity.mimeType === "text/plain" ||
          props.entity.mimeType?.includes("xml")) && (
          <Button
            className="mb-1 me-1"
            variant="outline-secondary"
            onClick={() => setPreviewShow(true)}
          >
            <FontAwesomeIcon icon={faEye} />
            {` Preview`}
          </Button>
        )}
        <RequiresRight
          requiredRight={RIGHT_ENTITY_DELETE}
          userRights={props.userRights}
        >
          <Button
            className="mb-1 me-1"
            variant="outline-danger"
            onClick={() => refConfirmModal.current?.open()}
          >
            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            {` Delete ${props.entity.fileType
              .toLocaleLowerCase()
              .replace("item", "linked item")}`}
          </Button>
        </RequiresRight>
        <RequiresRight
          requiredRight={RIGHT_ENTITY_UPDATE}
          userRights={props.userRights}
        >
          <Button
            className="mb-1 me-1"
            variant="outline-success"
            type="submit"
            disabled={!props.entity || !props.formDirty}
          >
            <FontAwesomeIcon icon={faFloppy}></FontAwesomeIcon>
            {` Save details`}
          </Button>
        </RequiresRight>
      </div>

      <ConfirmModal
        ref={refConfirmModal}
        header="Delete entity"
        text={`Are you sure you want to delete '${props.entity.displayName}' and any children?`}
        confirmText="Delete"
        onConfirm={() => {
          const deletePromise = deleteEntity({
            entityId: props.entity.id,
            item: props.entity.item,
          }).unwrap();

          toast.promise(deletePromise, {
            pending: `Deleting ${props.entity.fileType?.toLowerCase()} '${
              props.entity.displayName
            }' ...`,
            success: `${props.entity.fileType} '${props.entity.displayName}' successfully deleted.`,
            error: {
              render(toast) {
                const data = toast.data as any;
                return data.message === "Unauthorized"
                  ? `Not allowed to delete ${props.entity.fileType?.toLowerCase()} '${
                      props.entity.displayName
                    }'.`
                  : `Unable to delete ${props.entity.fileType?.toLowerCase()} '${
                      props.entity.displayName
                    }'.`;
              },
            },
          });
        }}
      />

      <FileDisplayModal
        entity={props.entity}
        show={previewShow}
        onClose={() => setPreviewShow(false)}
      />
    </>
  );
};

export default QrItemEntityToolbar;
