import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAppDispatch } from "../../../app/hooks";
import { toggle } from "../sidebar/sidebarSlice";
import logo from "../../../assets/images/vde48.png";
import "./topbar.css";

const Topbar: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <Navbar className="top-bar d-flex p-0" bg="light" expand="lg" sticky="top">
      <Nav.Item>
        <button
          id="btn-hamburger"
          className="btn mx-2"
          type="button"
          onClick={() => dispatch(toggle())}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </Nav.Item>
      <LinkContainer to="/">
        <Navbar.Brand>
          <img alt="" src={logo} width="30" height="30" />
          {"  "}
          QR-Service
        </Navbar.Brand>
      </LinkContainer>
    </Navbar>
  );
};

export default Topbar;
