import { RootState } from "../../../app/store";
import { createSlice } from "@reduxjs/toolkit";

export interface SideBarState {
  sidebarCollapsed: boolean;
}

const initialState: SideBarState = {
  sidebarCollapsed: false,
};

export const sideBarSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    toggle: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
  },
});

export const selectSidebarCollapsed = (state: RootState) => state.ui.sidebarCollapsed;

export const { toggle } = sideBarSlice.actions;

export default sideBarSlice.reducer;
