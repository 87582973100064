import { normalize, schema, NormalizedSchema } from "normalizr";

type entityKeys = "Entity";

export const normalizeResponse = <T>(
  responseData: T[],
  schema: schema.Array
): NormalizedSchema<
  {
    [k in entityKeys]: {
      [key: string]: T;
    };
  },
  number[]
> => {
  return normalize(responseData, schema);
};
