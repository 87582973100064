import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddRoleAssignmentModal from "./AddRoleAssignmentModal";
import { toast } from "react-toastify";
import { usePostRoleAssignmentMutation } from "./api/roleAssignmentQueries";
import RoleAssignment from "./interfaces/RoleAssignment";
import CreateRoleAssignment from "./interfaces/CreateRoleAssignment";

export interface AddRoleAssignmentButtonProps {
  disabled?: boolean;
  itemId?: number;
  companyId?: number;
  organizationId?: number;
  currentRoleAssignments?: RoleAssignment[];
}

/**
 * A button that shows a modal that allows assigning roles to users
 * @param props When none of the props are set, this will allow you to assign roles to anything.
 * Otherwise the role is assigned to the set props
 * @returns
 */
const AddRoleAssignmentButton: React.FC<AddRoleAssignmentButtonProps> = (
  props
) => {
  const [addModalShown, setAddModalShown] = useState(false);
  const [postRoleAssignment] = usePostRoleAssignmentMutation();

  const handleSubmit = (roleAssignment: CreateRoleAssignment) => {
    const createPromise = postRoleAssignment(roleAssignment).unwrap();
    setAddModalShown(false);

    toast.promise(createPromise, {
      pending: `Creating new role assignment ...`,
      success: `Role assignment successfully created.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to create role assignment.`
            : `Unable to create role assignment.`;
        },
      },
    });
  };
  return (
    <>
      <Button
        variant="primary"
        onClick={() => setAddModalShown(true)}
        disabled={props.disabled === undefined ? false : props.disabled}
      >
        {`Assign role`}
      </Button>
      <AddRoleAssignmentModal
        assignment={props}
        show={addModalShown}
        currentRoleAssignments={props.currentRoleAssignments}
        onCancel={() => setAddModalShown(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default AddRoleAssignmentButton;
