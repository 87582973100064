import FileContent from "../interfaces/FileContent";

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(typeof reader.result === "string" ? reader.result : "");
    reader.onerror = (error) => reject(error);
  });

export const toFileContent = async (file: File) => {
  const content = await toBase64(file);
  const newFile: FileContent = {
    content: content.substring(content.indexOf(",") + 1),
    mimeType: file.type,
  };
  return newFile;
};

export const downloadFile = (content: FileContent, fileName: string) => {
  const element = document.createElement("a");
  element.href = `data:${content.mimeType};base64,${content.content}`;
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
