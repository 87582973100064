import { Form as FormikForm, Formik } from "formik";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useAppSelector } from "../../../app/hooks";
import checkRight from "../../auth/checkRight";
import { RIGHT_ENTITY_UPDATE } from "../../auth/rights";
import { usePutEntityMutation } from "../entityQueries";
import { selectSelectedEntity } from "../entitySlice";
import Entity from "../interfaces/Entity";
import { FileType } from "../interfaces/FileType";
import QrItemEntityToolbar from "./QrItemEntityToolbar";
import QrItemFileVersions from "./QrItemFileVersions";

export interface QrItemFileDetailsProps {
  userRights?: string[];
}

const QrItemFileDetails: React.FC<QrItemFileDetailsProps> = (props) => {
  const entity = useAppSelector(selectSelectedEntity);
  const [putEntity] = usePutEntityMutation();

  return (
    <>
      <Formik
        initialValues={{
          ...entity,
          // default only when no entity is selected
          // Otherwise the values cannot be mapped to Entity
          id: entity ? entity.id : -1,
          displayName: entity ? entity.displayName : "",
          fileType: entity ? entity.fileType : FileType.Folder,
        }}
        enableReinitialize
        validationSchema={yup.object({
          displayName: yup
            .string()
            .trim()
            .required("Please enter a valid name"),
          description: yup.string().nullable(),
          context: yup
            .string()
            .nullable()
            .when("fileType", {
              is: FileType.Weblink,
              then: yup
                .string()
                .url()
                .required(
                  "Please enter a valid URL. Ensure it contains http(s)://"
                ),
            }),
        })}
        onSubmit={(values: Entity, { setSubmitting }) => {
          const updatePromise = putEntity(values).unwrap();
          setSubmitting(false);

          toast.promise(updatePromise, {
            pending: `Updating ${values.fileType?.toLowerCase()} '${
              values.displayName
            }' ...`,
            success: `${values.fileType} '${values.displayName}' successfully updated.`,
            error: {
              render(toast) {
                const data = toast.data as any;
                return data.message === "Unauthorized"
                  ? `Not allowed to update ${values.fileType?.toLowerCase()} '${
                      values.displayName
                    }'.`
                  : `Unable to update ${values.fileType?.toLowerCase()} '${
                      values.displayName
                    }'.`;
              },
            },
          });
        }}
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <FormikForm noValidate>
            <Form.Label>Name</Form.Label>
            <Form.Group className="mb-3">
              <Form.Control
                value={values.displayName}
                placeholder="Enter a display name ..."
                name="displayName"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.displayName && !!errors.displayName}
                disabled={
                  !entity ||
                  !checkRight({
                    requiredRight: RIGHT_ENTITY_UPDATE,
                    userRights: props.userRights,
                  })
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.displayName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                value={values.description ?? ""}
                placeholder="Enter a description ..."
                as="textarea"
                rows={3}
                name="description"
                onChange={handleChange}
                isInvalid={touched.description && !!errors.description}
                disabled={
                  !entity ||
                  !checkRight({
                    requiredRight: RIGHT_ENTITY_UPDATE,
                    userRights: props.userRights,
                  })
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>
            {entity && entity.fileType === FileType.Weblink && (
              <Form.Group className="mb-3">
                <Form.Label>Link</Form.Label>
                <Form.Control
                  value={values.context ?? ""}
                  placeholder="https://somewhere.com"
                  name="context"
                  onChange={handleChange}
                  isInvalid={touched.context && !!errors.context}
                  disabled={
                    !entity ||
                    !checkRight({
                      requiredRight: RIGHT_ENTITY_UPDATE,
                      userRights: props.userRights,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.context}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {entity && (
              <QrItemEntityToolbar
                entity={entity}
                formDirty={dirty}
                userRights={props.userRights}
              />
            )}
          </FormikForm>
        )}
      </Formik>
      {entity && (
        <QrItemFileVersions entity={entity} userRights={props.userRights} />
      )}
    </>
  );
};

export default QrItemFileDetails;
