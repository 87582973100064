export const RIGHT_ITEM_VIEW = "item-view";
export const RIGHT_ITEM_CREATE = "item-create";
export const RIGHT_ITEM_UPDATE = "item-update";
export const RIGHT_ITEM_DELETE = "item-delete";
export const RIGHT_COMPANY_VIEW = "company-view";
export const RIGHT_COMPANY_CREATE = "company-create";
export const RIGHT_COMPANY_UPDATE = "company-update";
export const RIGHT_COMPANY_DELETE = "company-delete";
export const RIGHT_ENTITY_VIEW = "entity-view";
export const RIGHT_ENTITY_CREATE = "entity-create";
export const RIGHT_ENTITY_UPDATE = "entity-update";
export const RIGHT_ENTITY_DELETE = "entity-delete";
export const RIGHT_ENTITY_VERSION = "entity-new-version";
export const RIGHT_ORGANIZATION_VIEW = "organization-view";
export const RIGHT_ORGANIZATION_CREATE = "organization-create";
export const RIGHT_ORGANIZATION_UPDATE = "organization-update";
export const RIGHT_ORGANIZATION_DELETE = "organization-delete";
export const RIGHT_ROLE_CREATE = "role-create";
export const RIGHT_ROLE_UPDATE = "role-delete";
export const RIGHT_ROLE_DELETE = "role-update";
export const RIGHT_ROLE_ASSIGNMENT_CREATE = "role-assignment-create";
export const RIGHT_ROLE_ASSIGNMENT_DELETE = "role-assignment-delete";
export const RIGHT_USERS_VIEW = "users-view";
export const RIGHT_ITEM_TEMPLATE_VIEW = "item-template-view";
export const RIGHT_ITEM_TEMPLATE_CREATE = "item-template-create";
export const RIGHT_ITEM_TEMPLATE_UPDATE = "item-template-update";
export const RIGHT_ITEM_TEMPLATE_DELETE = "item-template-delete";
export const RIGHT_ADMINISTRATION_VIEW = "administration-view";
