import { Formik, Form as FormikForm } from "formik";
import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Organization from "../../organization/interfaces/Organization";
import CreateCompany from "../interfaces/CreateCompany";
import * as yup from "yup";
import { toFileContent } from "../../common/Utilities/Utils";
import { useGetOrganizationsQuery } from "../../organization/api/organizationQueries";

export interface AddCompanyModalProps {
  show: boolean;
  onSubmit: (company: CreateCompany) => void;
  onCancel: () => void;
}

const IMAGE_FORMATS = ["image/png", "image/jpg", "image/jpeg", "image/gif"];

const AddCompanyModal: React.FC<AddCompanyModalProps> = (props) => {
  const { data: organizations } = useGetOrganizationsQuery();

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <Modal show={props.show} centered>
      <Formik
        initialValues={{
          name: "",
          code: "",
          companyId: -1,
          organizationId: -1,
          file: undefined,
        }}
        validationSchema={yup.object({
          name: yup.string().required("Please enter a valid name"),
          organizationId: yup
            .number()
            .positive("Please select an organization"),
          file: yup
            .mixed()
            .nullable()
            .test(
              "file",
              "Please upload an image",
              (value) => !value || IMAGE_FORMATS.includes(value?.type)
            ),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          const uploadedImage: File | undefined = values.file;

          const created: CreateCompany = {
            ...values,
            image: uploadedImage
              ? await toFileContent(uploadedImage)
              : undefined,
          };

          props.onSubmit(created);
          setSubmitting(false);
        }}
      >
        {({ handleChange, values, errors, touched, dirty, setFieldValue }) => (
          <FormikForm noValidate>
            <Modal.Header>Add Company</Modal.Header>
            <Modal.Body>
              <Form.Label>Company Name</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  required
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  as="select"
                  name="organizationId"
                  onChange={handleChange}
                  value={values.organizationId}
                  required
                  defaultValue={-1}
                  isInvalid={touched.organizationId && !!errors.organizationId}
                >
                  <option disabled hidden value={-1}>
                    Select an organization
                  </option>
                  {organizations?.map((o: Organization) => (
                    <option key={o.id} value={o.id}>
                      {o.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.organizationId}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Banner image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Select an image..."
                  name="file"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.currentTarget.files) {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }
                  }}
                  accept="image/*"
                  isInvalid={touched.file && !!errors.file}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={!dirty}>
                Add company
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddCompanyModal;
