import React, { useEffect } from "react";
import { getUserName } from "../../auth/authProvider";
import EntitiesHistory from "./EntitiesHistory";
import ItemsHistoryCards from "./ItemsHistoryCards";
import {
  useGetRecentlyUpdatedEntitiesQuery,
  useGetRecentlyAccessedItemsQuery,
} from "./homeApi";
import "./homePage.css";
import Toolbar from "../common/Toolbar";

const HomePage: React.FC = () => {
  const userName = getUserName();

  const {
    data: items,
    refetch,
    isLoading: isLoadingItems,
  } = useGetRecentlyAccessedItemsQuery({
    count: 5,
    loadBanners: true,
  });
  const { data: entities, isLoading: isLoadingEntities } =
    useGetRecentlyUpdatedEntitiesQuery({ pageSize: 5, sorts: ["-Timestamp"]});
    
  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      <Toolbar>
        <h1 className="h1">{`Welcome back, ${userName}`}</h1>
      </Toolbar>
      <ItemsHistoryCards items={items} isLoading={isLoadingItems} />
      <br />
      <EntitiesHistory entities={entities} isLoading={isLoadingEntities} showRedirect={true} />
    </>
  );
};

export default HomePage;
