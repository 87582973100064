import prettyBytes from "pretty-bytes";
import React from "react";
import { Card, Table } from "react-bootstrap";
import Organization from "../interfaces/Organization";
import SimpleCard from "../../common/SimpleCard";
import { useGetOrganizationMetricsQuery } from "../api/organizationQueries";

export interface OrganizationMetricsProps {
  organization: Organization;
}

const OrganizationMetrics: React.FC<OrganizationMetricsProps> = (props) => {
  const { data: metrics, isLoading } = useGetOrganizationMetricsQuery(
    props.organization.id
  );

  return (
    <>
      {metrics && metrics.length > 0 ? (
        <Card className="grid-card">
          <Card.Header>Metrics</Card.Header>
          <Card.Body className="p-0">
            <Table striped borderless className="mb-0">
              <tbody>
                {metrics?.map((metric) => (
                  <tr key={metric.name}>
                    <td className="col-md-2">
                      <span className="fw-bold">{metric.name}</span>
                      <br />
                      <span className="text-muted">{metric.description}</span>
                    </td>
                    <td className="col-md-2">
                      {metric.unit === "Bytes"
                        ? prettyBytes(metric.value)
                        : `${metric.value} ${metric.unit ?? ""}`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      ) : (
        <SimpleCard
          headerText="Metrics"
          displayText="No metrics available"
          loadingText="Loading organization metrics"
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default OrganizationMetrics;
