import RoleAssignment from "../interfaces/RoleAssignment";
import CreateRoleAssignment from "../interfaces/CreateRoleAssignment";
import { baseApi } from "../../common/baseApi";

export const roleAssignmentQueries = baseApi
  .enhanceEndpoints({ addTagTypes: ["RoleAssignments"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRoleAssignments: builder.query<
        RoleAssignment[],
        { itemId?: number; companyId?: number; organizationId?: number }
      >({
        query: (args) => ({
          url: `authorization/roles/assignments`,
          params: args,
        }),
        providesTags: ["RoleAssignments"],
      }),
      postRoleAssignment: builder.mutation<
        RoleAssignment,
        CreateRoleAssignment
      >({
        query: (body) => ({
          url: `authorization/roles/assignments`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["RoleAssignments"],
      }),
      deleteRoleAssignment: builder.mutation<number, number>({
        query: (assignmentId) => ({
          url: `authorization/roles/assignments/${assignmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["RoleAssignments"],
      }),
    }),
  });

export const { useGetRoleAssignmentsQuery, useDeleteRoleAssignmentMutation, usePostRoleAssignmentMutation } =
  roleAssignmentQueries;
