import { baseApi } from "../../common/baseApi";
import User from "../../auth/interfaces/User";

export const userQueries = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserByEmail: builder.mutation<User | undefined, string>({
      query: (address) => ({
        url: `users/email/${address}`,
      }),
    }),
  }),
});

export const { useGetUserByEmailMutation } = userQueries;
