import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { toFileContent } from "../../common/Utilities/Utils";
import QrItem from "../interfaces/QrItem";
import { useGetItemImageQuery } from "../qrItemQueries";
import FileContent from "../../common/interfaces/FileContent";

export interface EditItemModalProps {
  show: boolean;
  item: QrItem;
  onSubmit: (item: QrItem) => void;
  onCancel: () => void;
}

const IMAGE_FORMATS = ["image/png", "image/jpg", "image/jpeg", "image/gif"];

const EditItemModal: React.FC<EditItemModalProps> = (props) => {
  const [imagePreview, setImagePreview] = useState<FileContent | undefined>();

  const refImageInput = useRef<HTMLInputElement | null>(null);

  const { data, isError: imagePreviewFailed } = useGetItemImageQuery(
    { itemId: props.item.id, fallback: false },
    {
      skip: props.show === false,
    }
  );

  useEffect(() => {
    if (data) setImagePreview(data);
  }, [data]);

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <Modal show={props.show} centered>
      <Formik
        initialValues={{ ...props.item!, file: undefined }}
        validationSchema={yup.object({
          name: yup.string().required("Please enter a valid name"),
          description: yup.string(),
          companyId: yup.number().positive("Please select a company"),
          file: yup
            .mixed()
            .nullable()
            .test(
              "file",
              "Please choose an image as banner",
              (value) => !value || IMAGE_FORMATS.includes(value?.type)
            ),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          const created: QrItem = {
            ...values,
            image:
              imagePreview && imagePreview.content ? imagePreview : undefined,
          };

          console.log(created);
          props.onSubmit(created);
          setSubmitting(false);
        }}
      >
        {({ handleChange, values, errors, touched, dirty, setFieldValue }) => (
          <FormikForm noValidate>
            <Modal.Header>Edit QR Item</Modal.Header>
            <Modal.Body>
              <Form.Label>QR Item Name</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  required
                  disabled
                />
              </Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  isInvalid={touched.description && !!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Banner image</Form.Label>
                <Form.Control
                  ref={refImageInput}
                  type="file"
                  placeholder="Select an image..."
                  name="file"
                  onChange={async (
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    if (event.currentTarget.files) {
                      const uploadedFile = event.currentTarget.files[0];
                      const newImage: FileContent = await toFileContent(
                        uploadedFile
                      );
                      setImagePreview(newImage);
                      setFieldValue("file", uploadedFile);
                    }
                  }}
                  accept="image/*"
                  isInvalid={touched.file && !!errors.file}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              </Form.Group>

              {imagePreview && imagePreview.content && (
                <>
                  <label>Banner image preview</label>
                  {!imagePreviewFailed && (
                    <div>
                      <div>
                        <Image
                          className="image-preview"
                          src={`data:${imagePreview.mimeType};base64,${imagePreview.content}`}
                        />
                      </div>
                    </div>
                  )}
                  {imagePreviewFailed && (
                    <p className="text-danger fst-italic">
                      Unable to render image.
                    </p>
                  )}
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-danger"
                className="me-auto"
                onClick={() => {
                  if (!refImageInput.current) return;

                  refImageInput.current.value = "";
                  setFieldValue("file", "");
                  setImagePreview(undefined);
                }}
              >
                Remove banner image
              </Button>
              <Button variant="danger" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={!dirty}>
                Edit
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default EditItemModal;
