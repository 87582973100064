import { baseApi } from "../../common/baseApi";
import Role from "../../auth/interfaces/Role";

export const roleQueries = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<Role[], void>({
      query: () => ({
        url: `authorization/roles`,
      }),
    }),
  }),
});

export const { useGetRolesQuery } = roleQueries;
