import { Buffer } from "buffer";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import FileContent from "../../common/interfaces/FileContent";
import { FileType } from "../../entity/interfaces/FileType";
import "./EntityPreview.css";

interface EntityDisplayProps {
  entityContent?: FileContent | null;
  fileType?: FileType;
}

const QrItemFileEntityDisplay: React.FC<EntityDisplayProps> = ({
  entityContent,
  fileType,
}) => {
  const [currentEntityContent, setCurrentEntityContent] = useState<
    FileContent | undefined | null
  >(entityContent);

  useEffect(() => {
    setCurrentEntityContent(entityContent);
  }, [entityContent]);

  if (currentEntityContent === null)
    return <div className="alert alert-danger">Unable to load file.</div>;
  if (
    !currentEntityContent ||
    !currentEntityContent.mimeType ||
    !currentEntityContent.content
  )
    return (
      <Spinner
        animation="border"
        role="status"
        className="position-absolute top-50 start-50"
      />
    );

  if (currentEntityContent?.mimeType.includes("image")) {
    return (
      <img
        className="file-preview-image"
        src={`data:${currentEntityContent?.mimeType};base64,${currentEntityContent.content}`}
        alt={currentEntityContent?.mimeType}
      />
    );
  } else if (currentEntityContent.mimeType.includes("pdf")) {
    // Convert Base64 string to a binary blob to avoid size limit when loading.
    const binaryString = Buffer.from(
      currentEntityContent.content,
      "base64"
    ).toString("binary");
    const binaryArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      binaryArray[i] = binaryString.charCodeAt(i);
    }

    const iframeUrl = URL.createObjectURL(
      new Blob([binaryArray], { type: "application/pdf" })
    );

    return (
      <iframe title="PDF" src={iframeUrl} width="100%" height="500"></iframe>
    );
  } else if (
    currentEntityContent.mimeType === "text/plain" ||
    currentEntityContent.mimeType === "text/xml"
  ) {
    const buffer = Buffer.from(currentEntityContent.content, "base64");
    return <pre className="file-preview-code">{buffer.toString()}</pre>;
  }

  return null;
};

export default QrItemFileEntityDisplay;
