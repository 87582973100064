import React, { useEffect, useState } from "react";

export interface SorterProps {
  sortOption?: string;
  sortOptions?: { value: string; description: string }[];
  setSortOption?: (option: string) => void;
}

const Sorter: React.FC<SorterProps> = (props) => {
  const [sortOption, setSortOption] = useState<string | undefined>(
    props.sortOption
  );

  useEffect(() => {
    if (!props.setSortOption) return;
    if (!sortOption) return;

    props.setSortOption(sortOption);
  }, [props, sortOption]);

  return (
    <div className="mb-1 sorter d-flex justify-content-between">
      {props.sortOptions && (
        <select
          className="form-select"
          onChange={(e) => setSortOption(e.target.value)}
          value={props.sortOption}
        >
          {props.sortOptions.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                Sort: {option.description}
              </option>
            );
          })}
        </select>
      )}
      <div className="flex-fill" />
    </div>
  );
};

export default Sorter;
