import { useEffect, useState } from "react";
import { Row, Col, FormControl, Container } from "react-bootstrap";
import QrCard from "./QrCard";
import { Spinner } from "react-bootstrap";

import OverviewToolbar from "./OverviewToolbar";
import { useGetItemsQuery } from "../qrItemQueries";
import { toast } from "react-toastify";
import Paginator from "../../common/pagination/Paginator";
import BackToTopButton from "../../common/back-to-top/BackToTopButton";
import Sorter from "../../common/sorting/Sorter";
import PageSizeSelector from "../../common/pagination/PageSizeSelector";
import Filter from "../../common/filtering/Filter";
import { useGetCompaniesQuery } from "../../company/companyQueries";

function OverviewPage() {
  const [filterText, setFilterText] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [sortProperty, setSortProperty] = useState<string>("Name");
  const [querySortProperties, setQuerySortProperties] = useState<string[]>([
    "Name",
  ]);
  const [filterCompanies, setFilterCompanies] = useState<number[]>([]);
  const [queryFilterCompanies, setQueryFilterCompanies] = useState<string[]>(
    []
  );

  const sortOptions = [
    { value: "Name", description: "Item name (ascending)" },
    { value: "-Name", description: "Item name (descending)" },
    { value: "Company.Name", description: "Company name (ascending)" },
    { value: "-Company.Name", description: "Company name (descending)" },
  ];

  const { data: companies, isLoading: companiesIsLoading } =
    useGetCompaniesQuery();
  const { data, error, isLoading, isFetching } = useGetItemsQuery({
    page: pageNumber,
    pageSize: itemsPerPage,
    sorts: querySortProperties,
    filters: queryFilterCompanies,
  });

  useEffect(() => {
    // When the user sorts on the company name, ensure a secondary sort is added based on the item name.
    const properties = [sortProperty];

    if (sortProperty.startsWith("Company")) {
      properties.push("Name");
    }

    setQuerySortProperties(properties);
  }, [sortProperty]);

  useEffect(() => {
    const filters = [`(Name|Description|Company.Name)@=${filterText}`];

    if (filterCompanies.length > 0) {
      const filteredCompanyIds = filterCompanies.join("|");
      filters.push(`CompanyId==${filteredCompanyIds}`);
    }

    setQueryFilterCompanies(filters);
  }, [filterCompanies, filterText]);

  useEffect(() => {
    if (!error) return;
    toast.error("Unable to retrieve items");
  }, [error]);

  if (isLoading)
    return (
      <Spinner
        animation="border"
        role="status"
        className="position-absolute top-50 start-50"
      />
    );

  function handleKeyPress(event: any) {
    if (event.key !== "Enter") {
      return;
    }

    setFilterText(event.target.value);
  }

  return (
    <>
      <BackToTopButton />
      <OverviewToolbar />
      <Container fluid={true}>
        <Row className="mb-3">
          <Col>
            <FormControl
              className="mb-1"
              id="filter"
              name="filter"
              placeholder="Type and press enter to filter items..."
              aria-label="Filter"
              onKeyPress={handleKeyPress}
            />
          </Col>
          <Col md lg="2">
            {!companiesIsLoading && companies && (
              <Filter
                dataSource={
                  companies?.map((company) => ({
                    Name: company.name,
                    Id: company.id,
                    Category: company.organization.name,
                  })) ?? []
                }
                groupBy="Category"
                placeholder="Filter companies..."
                onFilter={(filteredIds) => setFilterCompanies(filteredIds)}
              />
            )}
          </Col>
          <Col md lg="2">
            <Sorter
              sortOption={sortProperty}
              sortOptions={sortOptions}
              setSortOption={(option) => setSortProperty(option)}
            />
          </Col>
          <Col md lg="2">
            <PageSizeSelector
              itemsPerPage={itemsPerPage}
              pageSizes={[12, 24, 50, 100]}
              setPageSize={(pageSize) => setItemsPerPage(pageSize)}
            />
          </Col>
        </Row>

        {data?.data && data?.data?.length > 0 && (
          <>
            {isFetching && (
              <Spinner
                animation="border"
                role="status"
                className="position-absolute top-50 start-50"
              />
            )}
            {!isFetching && (
              <>
                <Row xs={1} md={2} lg={4} xl={6} className="g-2">
                  {data?.data?.map((item) => (
                    <Col key={item.id}>
                      <QrCard item={item} />
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col>
                    <Paginator
                      currentPage={pageNumber}
                      totalItems={data?.totalCount}
                      itemsPerPage={itemsPerPage}
                      sortOption={sortProperty}
                      sortOptions={sortOptions}
                      setPageNumber={(pageNumber) => setPageNumber(pageNumber)}
                      setPageSize={(pageSize) => setItemsPerPage(pageSize)}
                      setSortOption={(option) => setSortProperty(option)}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default OverviewPage;
