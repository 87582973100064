import { createEntityAdapter } from "@reduxjs/toolkit";
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { schema } from "normalizr";
import PaginatedList from "../../api/PaginatedList";
import QueryParameters from "../../api/QueryParameters";
import FileContent from "../common/interfaces/FileContent";
import { companyQueries } from "../company/companyQueries";
import Entity from "../entity/interfaces/Entity";
import { normalizeResponse } from "../entity/normalizeResponse";
import CreateQrItem from "./interfaces/CreateQrItem";
import QrItem from "./interfaces/QrItem";

export const qrItemQueries = companyQueries
  .enhanceEndpoints({ addTagTypes: ["QrItems", "QrEmptyItems", "ItemImage"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Retrieve all QR Items
      getItems: builder.query<PaginatedList<QrItem>, QueryParameters>({
        query: (params) => ({
          url: `items`,
          params,
        }),
        providesTags: ["QrItems"],
      }),

      // Retrieve all empty QR Items
      getEmptyItems: builder.query<PaginatedList<QrItem>, QueryParameters>({
        query: (params) => ({
          url: `items/empty`,
          params,
        }),
        providesTags: ["QrEmptyItems"],
      }),

      // Retrieve a QR item by its slug
      getItemBySlug: builder.query<QrItem, string>({
        query: (slug) => `items/slug/${slug}`,
        providesTags: (result) => [{ type: "QrItems", id: result?.id }],
      }),

      // Retrieve a QR item by id
      getItemById: builder.query<QrItem, number>({
        query: (id) => `items/${id}`,
        providesTags: (result) => [{ type: "QrItems", id: result?.id }],
      }),

      // Retrieve the (optional) image of a QR item
      getItemImage: builder.query<
        FileContent,
        { itemId: number; fallback: boolean }
      >({
        query: (args) => ({
          url: `items/${args.itemId}/image`,
          params: { fallback: args.fallback },
        }),
        providesTags: (_result, _error, args) => [
          { type: "ItemImage", id: args.itemId },
        ],
      }),

      // Retrieve all entities for an item and normalize the data
      getEntitiesByItemId: builder.query<Entity[], number>({
        query: (itemId) => `items/${itemId}/entities`,
        providesTags: (_result, _error, itemId) => [
          { type: "QrItems", id: itemId },
        ],
        transformResponse: (response: Entity[], _meta, _arg) => {
          const entitySchema = new schema.Entity("Entity");
          const children = new schema.Array(entitySchema);
          entitySchema.define({ children });
          const entitiesSchema = new schema.Array(entitySchema);
          const normalized = normalizeResponse<Entity>(
            response,
            entitiesSchema
          );

          if (
            !normalized ||
            !normalized.entities ||
            !normalized.entities.Entity
          )
            return response;

          const entities = Object.keys(normalized.entities.Entity).map(
            (e) => normalized.entities.Entity[e]
          );

          return entities;
        },
      }),

      // Update a QR Item
      putItem: builder.mutation<QrItem, QrItem>({
        query: (body) => ({
          url: "items",
          method: "PUT",
          body,
        }),
        invalidatesTags: (result) => [
          "QrItems",
          { type: "QrItems", id: result?.id },
          { type: "ItemImage", id: result?.id },
        ],
      }),

      // Add a new QR Item
      postItem: builder.mutation<QrItem, CreateQrItem>({
        query: (body) => ({
          url: "items",
          method: "POST",
          body,
        }),
        invalidatesTags: (result) => [
          "QrItems",
          { type: "OrganizationMetrics", id: result?.company.organization.id },
        ],
      }),

      // Remove a QR Item
      deleteItem: builder.mutation<number, number>({
        query: (itemId) => ({
          url: `items/${itemId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["QrItems", "OrganizationMetrics"],
      }),
    }),
  });

export const {
  useGetItemsQuery,
  useGetItemBySlugQuery,
  useLazyGetItemByIdQuery,
  useGetItemImageQuery,
  useGetEmptyItemsQuery,
  useGetEntitiesByItemIdQuery,
  usePutItemMutation,
  usePostItemMutation,
  useDeleteItemMutation,
} = qrItemQueries;
