import { Card } from "react-bootstrap";
import { useAppSelector } from "../../../app/hooks";
import { FileType } from "../../entity/interfaces/FileType";
import QrItemFilesTree from "../../entity/tree/QrItemFilesTree";
import { useGetEntitiesByItemIdQuery } from "../qrItemQueries";

export interface ModalFileTreeProps {
  itemId: number;
  onNodeSelected: (nodeId: number) => void;
}

const ModalFileTree: React.FC<ModalFileTreeProps> = (props) => {
  const { folders } = useGetEntitiesByItemIdQuery(props.itemId, {
    selectFromResult: ({ data }) => ({
      folders: data
        ?.filter((e) => e.fileType === FileType.Folder)
        .map((f) => {
          // Link actual root folders to the new root folder (with ID -1).
          if (!f.entityId || f.entityId === null) f = { ...f, entityId: -1 };

          return f;
        })
        .concat({
          id: -1,
          entityId: null,
          displayName: "Root",
          fileType: FileType.Folder,
        }),
    }),
  });

  return (
    <Card className="card-tree">
      <Card.Header>
        <Card.Title className="mb-0">Parent Folder</Card.Title>
      </Card.Header>
      <Card.Body className="overflow-auto m-0 p-0">
        {folders && (
          <QrItemFilesTree
            itemId={props.itemId}
            entities={folders}
            onNodeSelected={props.onNodeSelected}
            readonly={true}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default ModalFileTree;
