import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { getAccount } from "../../auth/authProvider";
import { Tabs, Tab } from "react-bootstrap";
import Toolbar from "../common/Toolbar";
import { AccountInfo } from "@azure/msal-browser";

const AccountPage: React.FC = () => {
  const [userInfo, setUserInfo] = useState<AccountInfo>();

  useEffect(() => {
    setUserInfo(getAccount());
  });
  const unsubscribeLinkToMail = `mailto:info@vandoren.nl?subject=Unsubscribe from QR-Service&body=Dear support team,%0D%0A%0D%0AI would like to unsubscribe from the QR-Service. Please proceed with the necessary steps.%0D%0A%0D%0AThank you,%0D%0A${
    userInfo?.name || ""
  },%0D%0A${userInfo?.username || ""}`;

  return (
    <>
      <Tabs defaultActiveKey="Information" className="mb-3" variant="tabs">
        <Tab eventKey="Information" title="Information">
          <Toolbar>
            <h1 className="h1">{userInfo?.name}</h1>
            <Button
              variant="primary"
              onClick={() => (window.location.href = unsubscribeLinkToMail)}
            >
              <FontAwesomeIcon icon={faUserSlash} />
              {` Unsubscribe`}
            </Button>
          </Toolbar>
          <p>
            To unsubscribe from QR-Service, click the "Unsubscribe" button above
            or <br />
            send an email to{" "}
            <a href={unsubscribeLinkToMail}>info@vandoren.nl</a> with the
            subject "Unsubscribe from QR-Service".
            <br />
          </p>
        </Tab>
      </Tabs>
    </>
  );
};

export default AccountPage;
