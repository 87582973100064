import { getAccessToken } from "../auth/authProvider";

export async function handleResponse(response: any) {
  if (response.ok && response.status === 204) return null;
  if (response.ok) return response.json();

  const errorText = await response.text();
  throw new Error(errorText);
}

// In a real app, would likely call an error logging service.
export function handleError(error: any) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

export async function getHeaders(): Promise<Headers> {
  const headers: HeadersInit = new Headers();
  headers.set("Authorization", `Bearer ${await getAccessToken()}`);
  headers.append("content-type", "application/json");
  return headers;
}
