import React, { useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import AddFileVersionModal from "../../qr-item/modals/AddFileVersionModal";
import FileDisplayModal from "../../qr-item/modals/FileDisplayModal";
import { toast } from "react-toastify";
import { RIGHT_ENTITY_VERSION } from "../../auth/rights";
import checkRight from "../../auth/checkRight";
import Entity from "../interfaces/Entity";
import EntityVersion from "../interfaces/EntityVersion";
import { FileType } from "../interfaces/FileType";
import CreateEntityVersion from "../interfaces/CreateEntityVersion";
import {
  useGetEntityVersionsQuery,
  useLazyGetEntityContentQuery,
  usePutEntityContentMutation,
} from "../entityQueries";
import { downloadFile } from "../../common/Utilities/Utils";

interface QrItemFileVersionsProps {
  entity: Entity;
  userRights?: string[];
}

const QrItemFileVersions: React.FC<QrItemFileVersionsProps> = ({
  entity,
  userRights,
}) => {
  const [previewShow, setPreviewShow] = useState<boolean>(false);
  const [showNewFileVersionModal, setShowNewFileVersionModal] =
    useState<boolean>(false);

  const [currentVersion, setCurrentVersion] = useState<EntityVersion>();

  const { data: versions } = useGetEntityVersionsQuery(entity.id);
  const [putEntityContent] = usePutEntityContentMutation();
  const [getEntityContent] = useLazyGetEntityContentQuery();

  if (!entity) return <></>;

  const toolbarOptions: object[] = [
    { id: "NewVersion", text: "Add new version", prefixIcon: "e-add" },
  ];

  const commandTemplate = (version: EntityVersion) => {
    return (
      <>
        {(version.entity?.mimeType?.includes("image") ||
          version.entity?.mimeType?.includes("pdf") ||
          version.entity?.mimeType === "text/plain" ||
          version.entity?.mimeType === "text/xml") && (
          <FontAwesomeIcon
            className="hover-icon download me-2"
            icon={faEye}
            onClick={() => {
              setCurrentVersion(version);
              setPreviewShow(true);
            }}
          />
        )}
        <FontAwesomeIcon
          className="hover-icon download me-2"
          icon={faDownload}
          onClick={() => downloadFileVersion(version)}
        />
      </>
    );
  };

  const sizeTemplate = (version: EntityVersion) => {
    const sizeInMB = version.size / 1024 / 1024;
    return <>{sizeInMB.toFixed(2)}</>;
  };

  const toolbarClick = (args: any) => {
    if (args.item.id !== "NewVersion") return;
    setShowNewFileVersionModal(true);
  };

  const submitNewVersion = (file: CreateEntityVersion) => {
    const updatePromise = putEntityContent({
      entityId: entity.id,
      newVersion: file,
    });
    setShowNewFileVersionModal(false);

    toast.promise(updatePromise, {
      pending: `Uploading new version for '${entity.displayName}' ...`,
      success: `New version for '${entity.displayName}' successfully uploaded.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to upload new version for '${entity.displayName}'.`
            : `Unable to upload new version for '${entity.displayName}'.`;
        },
      },
    });
  };

  const downloadFileVersion = (entityVersion: EntityVersion) => {
    if (!entityVersion.entity) return;
    if (entityVersion.entity.fileType === FileType.Folder) return;
    if (!entityVersion.entity.fileName) return;

    const downloadPromise = getEntityContent({
      entityId: entityVersion.entity.id,
      versionId: entityVersion.versionId,
    })
      .unwrap()
      .then((content) => {
        if (entityVersion.entity?.fileName)
          downloadFile(content, entityVersion.entity.fileName);
      });

    toast.promise(downloadPromise, {
      pending: `Downloading '${entityVersion.entity.displayName}' ...`,
      success: `'${entityVersion.entity.displayName}' downloaded.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to download '${entityVersion.entity?.displayName}'.`
            : `Unable to download '${entityVersion.entity?.displayName}'.`;
        },
      },
    });
  };

  return (
    <>
      {entity.fileType === FileType.File && versions && (
        <GridComponent
          dataSource={versions}
          toolbar={toolbarOptions}
          toolbarClick={toolbarClick}
          height={250}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="timestampZone"
              headerText="Created on"
              width="55"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
            />
            <ColumnDirective
              field="user.name"
              headerText="Created by"
              width="50"
            />
            <ColumnDirective field="notes" headerText="Notes" width="105" />
            <ColumnDirective
              field="size"
              headerText="Size (MB)"
              width="35"
              template={sizeTemplate}
            />
            <ColumnDirective width="30" template={commandTemplate} />
          </ColumnsDirective>
          {checkRight({
            requiredRight: RIGHT_ENTITY_VERSION,
            userRights: userRights,
          }) && <Inject services={[Toolbar]} />}
        </GridComponent>
      )}

      <AddFileVersionModal
        show={showNewFileVersionModal}
        onSubmit={submitNewVersion}
        onCancel={() => setShowNewFileVersionModal(false)}
      />
      <FileDisplayModal
        entity={entity}
        entityVersion={currentVersion}
        show={previewShow}
        onClose={() => setPreviewShow(false)}
      />
    </>
  );
};

export default QrItemFileVersions;
