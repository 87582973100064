import { Form as FormikForm, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import { useAppSelector } from "../../../app/hooks";
import CreateEntity from "../../entity/interfaces/CreateEntity";
import { FileType } from "../../entity/interfaces/FileType";
import { selectSelectedEntityFolder } from "../../entity/entitySlice";
import QrItem from "../interfaces/QrItem";
import {
  useGetEntitiesByItemIdQuery,
  useGetItemsQuery,
} from "../qrItemQueries";
import ModalFileTree from "./ModalFileTree";

export interface AddLinkedItemModalProps {
  show: boolean;
  itemId: number;
  onSubmit: (link: CreateEntity) => void;
  onCancel: () => void;
}

const AddLinkedItemModal: React.FC<AddLinkedItemModalProps> = (props) => {
  const { data: entities } = useGetEntitiesByItemIdQuery(props.itemId);
  const selectedFolderId = useAppSelector(selectSelectedEntityFolder(entities));
  const [selectedFolder, setSelectedFolder] = useState(selectedFolderId);

  // Use a memo for the file tree to avoid re-rendering on state change when selecting a new folder.
  // Otherwise, the tree will flicker back to the original entity selection.
  const fileTree = useMemo(
    () => (
      <ModalFileTree
        itemId={props.itemId}
        onNodeSelected={(id: number) => setSelectedFolder(id)}
      />
    ),
    [props.itemId, entities]
  );

  const { data: items } = useGetItemsQuery({
    filters: [`Id!=${props.itemId}`],
  });

  return (
    <Modal show={props.show} centered>
      <Formik
        initialValues={{
          displayName: "",
          description: "",
          context: "-1",
          itemId: props.itemId,
          entityId: selectedFolder === -1 ? undefined : selectedFolder,
          fileType: FileType.Item,
        }}
        validationSchema={yup.object({
          displayName: yup
            .string()
            .trim()
            .required("Please enter a valid name."),
          description: yup.string(),
          context: yup.string().not(["-1"], "Please select an item to link."),
        })}
        onSubmit={(values: CreateEntity, { setSubmitting }) => {
          values.entityId = selectedFolder === -1 ? undefined : selectedFolder;
          props.onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
          dirty,
          isValid,
        }) => (
          <FormikForm noValidate>
            <Modal.Header>Add Linked Item</Modal.Header>
            <Modal.Body>
              {fileTree}
              <Form.Group className="mb-3" controlId="validationCustom02">
                <Form.Label>Linked Item</Form.Label>
                <Form.Control
                  as="select"
                  name="context"
                  onChange={handleChange}
                  value={values.context}
                  required
                  defaultValue={"-1"}
                  isInvalid={touched.context && !!errors.context}
                >
                  <option disabled hidden value={"-1"}>
                    Select an item
                  </option>
                  {items?.data?.map((i: QrItem) => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.context}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="validationCustom03">
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                  placeholder="Enter display name..."
                  name="displayName"
                  onChange={handleChange}
                  required
                  isInvalid={touched.displayName && !!errors.displayName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.displayName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="mb-3"
                placeholder="Enter a description..."
                name="description"
                onChange={handleChange}
                as="textarea"
                rows={3}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={props.onCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={!dirty}>
                Add Linked Item
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddLinkedItemModal;
