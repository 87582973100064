import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Dropdown, SplitButton } from "react-bootstrap";
import AddFolderModal from "../modals/AddFolderModal";
import AddLinkModal from "../modals/AddLinkModal";
import AddFileModal from "../modals/AddFileModal";
import { useAppDispatch } from "../../../app/hooks";
import ConfirmModal from "../../common/modals/ConfirmModal";
import EditableHeader from "../../common/EditableHeader";
import Toolbar from "../../common/Toolbar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EditItemModal from "../modals/EditItemModal";
import AddLinkedItemModal from "../modals/AddLinkedItemModal";
import RequiresRight from "../../auth/RequiresRight";
import {
  RIGHT_ENTITY_CREATE,
  RIGHT_ITEM_DELETE,
  RIGHT_ITEM_UPDATE,
} from "../../auth/rights";
import QrItem from "../interfaces/QrItem";
import { useDeleteItemMutation, usePutItemMutation } from "../qrItemQueries";
import CreateEntity from "../../entity/interfaces/CreateEntity";
import { usePostEntityMutation } from "../../entity/entityQueries";

export interface QrItemToolbarProps {
  item: QrItem;
}

const QrItemToolbar: React.FC<QrItemToolbarProps> = (props) => {
  const refConfirmModal = useRef<ConfirmModal>(null);
  const navigate = useNavigate();
  const [modalType, setModalType] = useState<
    "Folder" | "File" | "Link" | "Item" | undefined
  >(undefined);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [putItem] = usePutItemMutation();
  const [deleteItem] = useDeleteItemMutation();
  const [postEntity] = usePostEntityMutation();

  const handleEntitySubmit = (entity: CreateEntity) => {
    entity.itemId = props.item.id;
    const postPromise = postEntity(entity).unwrap();
    toast.promise(postPromise, {
      pending: `Submitting '${entity.displayName}'...`,
      success: `'${entity.displayName}' successfully submitted.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to upload '${entity.displayName}'.`
            : `Unable to upload '${entity.displayName}'.`;
        },
      },
    });

    setModalType(undefined);
  };

  const handleDelete = () => {
    refConfirmModal.current?.open();
  };

  const handleItemNameChange = (name: string) => {
    const updatedItem = { ...props.item, name } as QrItem;

    const updatePromise = putItem(updatedItem).unwrap();

    toast.promise(updatePromise, {
      pending: `Updating item '${name}' ...`,
      success: `Item '${name}' updated.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to update item '${name}'.`
            : `Unable to update item '${name}'.`;
        },
      },
    });
  };

  const handleItemUpdate = (item: QrItem) => {
    const updatePromise = putItem(item)
      .unwrap()
      .then(() => setShowEditModal(false));

    toast.promise(updatePromise, {
      pending: `Updating item '${item.name}' ...`,
      success: `Item '${item.name}' updated.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to update item '${item.name}'.`
            : `Unable to update item '${item.name}'.`;
        },
      },
    });
  };

  return (
    <>
      <Toolbar>
        <EditableHeader
          requiredRight={RIGHT_ITEM_UPDATE}
          userRights={props.item.rights}
          text={props.item.name}
          onSubmit={handleItemNameChange}
        />
        <RequiresRight
          requiredRight={RIGHT_ITEM_UPDATE}
          userRights={props.item.rights}
        >
          <Button variant="secondary" onClick={() => setShowEditModal(true)}>
            <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>
            {` Edit item`}
          </Button>
        </RequiresRight>
        <RequiresRight
          requiredRight={RIGHT_ENTITY_CREATE}
          userRights={props.item.rights}
        >
          <SplitButton
            variant="primary"
            title="Add files"
            id="Uploadbutton"
            key="Uploadbutton"
            onClick={() => setModalType("File")}
          >
            <Dropdown.Item eventKey="1" onClick={() => setModalType("File")}>
              File
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => setModalType("Folder")}>
              Folder
            </Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={() => setModalType("Link")}>
              Weblink
            </Dropdown.Item>
            <Dropdown.Item eventKey="4" onClick={() => setModalType("Item")}>
              Linked Item
            </Dropdown.Item>
          </SplitButton>
        </RequiresRight>
        <RequiresRight
          requiredRight={RIGHT_ITEM_DELETE}
          userRights={props.item.rights}
        >
          <Button variant="danger" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            {` Delete item`}
          </Button>
        </RequiresRight>
      </Toolbar>

      <AddFolderModal
        show={modalType === "Folder"}
        itemId={props.item.id}
        onCancel={() => setModalType(undefined)}
        onSubmit={handleEntitySubmit}
      />
      <AddLinkModal
        show={modalType === "Link"}
        itemId={props.item.id}
        onCancel={() => setModalType(undefined)}
        onSubmit={handleEntitySubmit}
      />
      <AddFileModal
        show={modalType === "File"}
        itemId={props.item.id}
        onCancel={() => setModalType(undefined)}
        onSubmit={handleEntitySubmit}
      />
      <AddLinkedItemModal
        show={modalType === "Item"}
        itemId={props.item.id}
        onCancel={() => setModalType(undefined)}
        onSubmit={handleEntitySubmit}
      />

      <EditItemModal
        show={showEditModal}
        item={props.item}
        onCancel={() => setShowEditModal(false)}
        onSubmit={handleItemUpdate}
      />

      <ConfirmModal
        ref={refConfirmModal}
        header="Delete item"
        text={`Are you sure you want to delete '${props.item.name}' and its files and folders?`}
        confirmText="Delete"
        onConfirm={() => {
          const deletePromise = deleteItem(props.item.id)
            .unwrap()
            .then(() => navigate("../items"));

          toast.promise(deletePromise, {
            pending: `Deleting item '${props.item.name}' ...`,
            success: `Item '${props.item.name}' deleted.`,
            error: {
              render(toast) {
                const data = toast.data as any;
                return data.message === "Unauthorized"
                  ? `Not allowed to delete item '${props.item.name}'.`
                  : `Unable to delete item '${props.item.name}'.`;
              },
            },
          });
        }}
      />
    </>
  );
};

export default QrItemToolbar;
