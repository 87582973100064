import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CreateCompany from "../interfaces/CreateCompany";
import AddCompanyModal from "./AddCompanyModal";
import Toolbar from "../../common/Toolbar";
import { toast } from "react-toastify";
import { usePostCompanyMutation } from "../companyQueries";
import RequiresRight from "../../auth/RequiresRight";
import { RIGHT_COMPANY_CREATE } from "../../auth/rights";
import { useGetInitialRightsByUserQuery } from "../../auth/authorizationQueries";

const CompaniesToolbar: React.FC = () => {
  const [modalShown, setModalShown] = useState(false);

  const { data: initialRights } = useGetInitialRightsByUserQuery();
  const [postCompany] = usePostCompanyMutation();

  const handleSubmit = (company: CreateCompany) => {
    const createPromise = postCompany(company).unwrap();

    setModalShown(false);

    toast.promise(createPromise, {
      pending: `Creating new company '${company.name}' ...`,
      success: `New company '${company.name}' created.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to create new company '${company.name}'.`
            : `Unable to create new company '${company.name}'.`;
        },
      },
    });
  };

  return (
    <>
      <Toolbar>
        <h1>Companies</h1>
        <RequiresRight
          requiredRight={RIGHT_COMPANY_CREATE}
          userRights={initialRights}
        >
          <Button variant="primary" onClick={() => setModalShown(true)}>
            {`Add Company`}
          </Button>
        </RequiresRight>
      </Toolbar>
      <AddCompanyModal
        show={modalShown}
        onCancel={() => setModalShown(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default CompaniesToolbar;
