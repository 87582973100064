import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./back-to-top.css";

export interface BackToTopButtonProps {}

const BackToTopButton: React.FC<BackToTopButtonProps> = (props) => {
  const [buttonVisible, setButtonVisible] = useState<boolean>(false);

  const handleScroll = () => {
    const position = document.querySelector(".page-content")?.scrollTop;
    if (position !== undefined) {
      setButtonVisible(position > 20);
    }
  };

  const handleScrollToTop = () => {
    document
      .querySelector(".page-content")
      ?.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Subscribe to the scroll event to show/hide the "back to top" button.
  useEffect(() => {
    document
      .querySelector(".page-content")
      ?.addEventListener("scroll", handleScroll);

    return () => {
      document
        .querySelector(".page-content")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Keep track of the page size to ensure the "back to top" button is hidden
  // when the page gets smaller and is no longer scrolled.
  // The scroll event does not fire in that case.
  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      handleScroll();
    });

    const pageContent = document.querySelector(".page-content");
    if (pageContent) resizeObserver.observe(pageContent);
  });

  return (
    <button
      type="button"
      className={`btn btn-primary btn-floating btn-lg btn-back-to-top ${
        buttonVisible ? "" : "btn-back-to-top-hidden"
      }`}
      onClick={handleScrollToTop}
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </button>
  );
};

export default BackToTopButton;
