import React from "react";
import { Card, Button } from "react-bootstrap";
import FileContent from "../../common/interfaces/FileContent";
import Company from "../interfaces/Company";

export interface CompanyCardProps {
  company: Company;
  image?: FileContent;
  onClick: (file: File | undefined) => void;
}

const CompanyCard: React.FC<CompanyCardProps> = (props) => {
  console.log(props.image);

  return (
    <Card className="qr-card shadow col-xs-12 col-md-6 col-lg-3 col-xl-2 g-2">
      <div className="text-center p-2">
        <Card.Img
          className="card img"
          variant="top"
          src={
            props.image && props.image.content
              ? `data:${props.image.mimeType};base64,${props.image.content}`
              : `data:image/svg+xml;utf8,
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 100" height="100px" width="180px">
                    <style> .text {font: bold 20px sans-serif; fill: grey; } </style>
                    <rect width="180" height="100" style="fill:gray;stroke:dark-gray;fill-opacity:0.1;stroke-opacity:0.9" />
                    <text class="text" x="50%" y ="50%" dominant-baseline="middle" text-anchor="middle"> No image </text>
                  </svg>`
          }
          alt={`${props.company.name}-banner`}
        />
      </div>
      <Card.Body>
        <Card.Title className="itemCardHeader">Item Name</Card.Title>
        <Card.Subtitle className="itemCardSubtitle text-muted text-truncate">
          {props.company.name}
        </Card.Subtitle>
        <Card.Text className="itemCardDescription text-muted text-truncate">
          Item Description
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <Button disabled variant="outline-primary" className="float-end">
          Go to item
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default CompanyCard;
