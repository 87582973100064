import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import React from "react";
import { Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Organization from "../interfaces/Organization";
import Company from "../../company/interfaces/Company";
import { useGetOrganizationCompaniesQuery } from "../api/organizationQueries";
import SimpleCard from "../../common/SimpleCard";

export interface OrganizationCompaniesProps {
  organization: Organization;
}

const OrganizationCompanies: React.FC<OrganizationCompaniesProps> = (props) => {
  const { data: companies, isLoading } = useGetOrganizationCompaniesQuery(
    props.organization.id
  );

  const dataAvailable = companies && companies.length > 0;
  const companyLinkTemplate = (company: Company) => {
    return <NavLink to={`/companies/${company.slug}`}>{company.name}</NavLink>;
  };

  return (
    <>
      {dataAvailable ? (
        <Card className="grid-card">
          <Card.Header className="border-0">Companies</Card.Header>
          <Card.Body className={"p-0"}>
            <GridComponent
              className="border-0"
              allowSorting={true}
              dataSource={companies}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="name"
                  headerText="Company name"
                  template={companyLinkTemplate}
                />
              </ColumnsDirective>
            </GridComponent>
          </Card.Body>
        </Card>
      ) : (
        <SimpleCard
          loadingText="Loading companies..."
          displayText="No companies available."
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default OrganizationCompanies;
