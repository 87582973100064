import { baseApi } from "../common/baseApi";
import EntityVersion from "../entity/interfaces/EntityVersion";
import PaginatedList from "../../api/PaginatedList";
import QueryParameters from "../../api/QueryParameters";

export const activityApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecentlyUpdatedEntities: builder.query<PaginatedList<EntityVersion>, QueryParameters>({
      query: (params) => ({
          url: `metadata/updated-entities`,
          params
        })
    }),
  }),
});

export const { useGetRecentlyUpdatedEntitiesQuery } = activityApi;
