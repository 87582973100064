import "react-pro-sidebar/dist/css/styles.css";
import "./App.css";
import QrItemPage from "./components/qr-item/details/QrItemPage";
import OverviewPage from "./components/qr-item/overview/OverviewPage";
import CompanyDetails from "./components/company/details/CompanyDetails";
import CompanyOverviewPage from "./components/company/overview/CompanyOverview";
import OrganizationsOverviewPage from "./components/organization/overview/OrganizationsOverviewPage";
import OrganizationPage from "./components/organization/details/OrganizationPage";
import NotFoundPage from "./components/common/NotFoundPage";
import AccountPage from "./components/account/AccountPage";
import LoginPage from "./components/auth/LoginPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Topbar from "./components/common/topbar/Topbar";
import Sidebar from "./components/common/sidebar/Sidebar";
import AuthUnauthenticatedPage from "./components/auth/AuthUnauthenticatedPage";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import AdministrationPage from "./components/administration/AdministrationPage";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./components/home/HomePage";
import ActivityPage from "./components/activity/ActivityPage";
import ErrorBoundary from "./components/common/ErrorBoundary";

function App() {
  return (
    <BrowserRouter>
      <AuthenticatedTemplate>
        <Topbar />
        <div className="main-content d-flex">
          <Sidebar />
          <div className="page-content flex-grow-1 overflow-auto p-3">
            <ErrorBoundary>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/activity" element={<ActivityPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/items" element={<OverviewPage />} />
                <Route path="/users" element={<AdministrationPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/companies" element={<CompanyOverviewPage />} />
                <Route path="/companies/:slug" element={<CompanyDetails />} />
                <Route
                  path="/organizations"
                  element={<OrganizationsOverviewPage />}
                />
                <Route
                  path="/organizations/:slug"
                  element={<OrganizationPage />}
                />
                <Route path="/items/:slug" element={<QrItemPage />} />
                <Route path="/404" element={<NotFoundPage />} />
                <Route element={<NotFoundPage />} />
              </Routes>
            </ErrorBoundary>
          </div>
        </div>
      </AuthenticatedTemplate>
      <ToastContainer position="bottom-right" hideProgressBar={true} />
      <UnauthenticatedTemplate>
        <AuthUnauthenticatedPage />
      </UnauthenticatedTemplate>
    </BrowserRouter>
  );
}

export default App;
