import { msalConfig, tokenRequest } from "./msalConfig";
import { AccountInfo, PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(msalConfig);

export const handleSignIn = () => {
  msalInstance.loginRedirect(tokenRequest).catch((e) => {
    console.error(e);
  });
};

export const handleSignOut = () => {
  msalInstance.logoutRedirect();
};

export  function getAccount(): AccountInfo {
  return msalInstance.getAllAccounts()[0];
}

export async function getAccessToken(): Promise<string> {
  const account = msalInstance.getAllAccounts()[0];

  const request = {
    ...tokenRequest,
    account: account,
  };

  let token = "";

  await msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      token = response.accessToken;
    })
    .catch((e) => {
      console.error(`Failed to aquire access token: ${e}`);
      handleSignOut();
    });

  return token;
}

export const getUserName = () => {
  return msalInstance.getAllAccounts()[0].name;
};
