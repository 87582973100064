import { History, Transition } from "history";
import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

type ExtendNavigator = Navigator & Pick<History, "block">;

/**
 * useBlocker is a low-level hook that allows you to block navigation away from the current page,
 * i.e. prevent the current location from changing. This is probably something you don't ever want
 * to do unless you also display a confirmation dialog to the user to help them understand why their
 * navigation attempt was blocked. In these cases, you probably want to use usePrompt instead.
 *
 * @param blocker Callback/transition used to block the navigation attempt (f.e. displaying a confirm window).
 * @param when Condition for when to apply the blocker.
 */
export function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as any as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * The usePrompt hook may be used to confirm navigation before the user navigates away from the current page.
 * This is useful when someone has entered unsaved data into a form, and you'd like to prompt them before they
 * accidentally leave or close the tab and lose their work.
 *
 * @param message Message to display to the user.
 * @param when Condition for when to display the prompt.
 */
export default function usePrompt(message: string, when = true) {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when);
}
