import React, { useEffect, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Modal, Button, Form } from "react-bootstrap";
import { useAppSelector } from "../../../app/hooks";
import { selectSelectedEntity } from "../../entity/entitySlice";
import * as yup from "yup";
import * as utils from "../../../components/common/Utilities/Utils";
import CreateEntityVersion from "../../entity/interfaces/CreateEntityVersion";
import {
  SelectedEventArgs,
  UploaderComponent,
} from "@syncfusion/ej2-react-inputs";
import { resetColumns } from "@syncfusion/ej2-grids";
export interface AddFileVersionModalProps {
  show: boolean;
  onSubmit: (folder: CreateEntityVersion) => void;
  onCancel: () => void;
}

const AddFileVersionModal: React.FC<AddFileVersionModalProps> = (props) => {
  const selectedEntity = useAppSelector(selectSelectedEntity);
  const [fileTypeMatching, setFileTypeMatching] = useState<boolean>();

  return (
    <Modal show={props.show} centered>
      <Formik
        initialValues={{
          fileName: "",
          versionNotes: "",
          content: "",
          file: null,
        }}
        validationSchema={yup.object({
          file: yup
            .mixed()
            .nullable()
            .required("Please upload a new version")
            .test(
              "file",
              "File type does not match the current file.",
              (value) => value && value.type === selectedEntity?.mimeType
            ),
          versionNotes: yup.string(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.file == null) return;

          let uploadedFile = values.file as File;
          values.content = await utils.toBase64(uploadedFile);

          const created: CreateEntityVersion = {
            ...values,
            fileName: uploadedFile.name,
          };
          props.onSubmit(created);
          setSubmitting(false);
        }}
      >
        {({
          handleChange,
          values,
          errors,
          setFieldValue,
          dirty,
          resetForm,
        }) => (
          <FormikForm noValidate>
            <Modal.Header>
              Add new file version for '{selectedEntity?.displayName}'
              {fileTypeMatching === false && (
                <div style={{ color: "red" }}>Error: File type must match!</div>
              )}
            </Modal.Header>
            <Modal.Body>
              <Form.Label>File</Form.Label>
              <Form.Group className="mb-3">
                <UploaderComponent
                  autoUpload={false}
                  maxFileSize={1.5e8}
                  multiple={false}
                  removing={() => {
                    setFieldValue("file", null);
                  }}
                  selected={(args: SelectedEventArgs) => {
                    setFieldValue("file", args.filesData[0].rawFile);
                    if (
                      selectedEntity?.mimeType
                        ?.toLowerCase()
                        .includes(args.filesData[0].type.toLowerCase() ?? "")
                    )
                      setFileTypeMatching(true);
                    else {
                      resetForm();
                      setFileTypeMatching(false);
                      args.cancel = true;
                    }
                  }}
                ></UploaderComponent>
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Label>Version Notes (optional)</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Version notes"
                  name="versionNotes"
                  onChange={handleChange}
                  value={values.versionNotes}
                  as="textarea"
                  rows={3}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.versionNotes}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => {
                  props.onCancel();
                  setFileTypeMatching(undefined);
                }}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={!dirty}>
                Add new version
              </Button>
              {/* {fileTypeMatching === false && <div>File type must match</div>} */}
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddFileVersionModal;
