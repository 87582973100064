import React from "react";

type ErrorProps = {};

type ErrorState = {
  hasError: Boolean;
};

class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  state: ErrorState = {
    hasError: false,
  };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className="alert alert-danger">Something went wrong</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
