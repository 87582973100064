import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import RequiresRight from "../../auth/RequiresRight";
import AddItemModal from "./AddItemModal";
import { RIGHT_ITEM_CREATE } from "../../auth/rights";
import { useGetInitialRightsByUserQuery } from "../../auth/authorizationQueries";
import CreateQrItem from "../interfaces/CreateQrItem";
import { usePostItemMutation } from "../qrItemQueries";

const OverviewToolbar: React.FC = () => {
  const [modalShown, setModalShown] = useState(false);

  const { data: initialRights } = useGetInitialRightsByUserQuery();
  const [postItem] = usePostItemMutation();

  const handleSubmit = (item: CreateQrItem) => {
    const createPromise = postItem(item).unwrap();

    setModalShown(false);

    toast.promise(createPromise, {
      pending: `Creating new item '${item.name}' ...`,
      success: `New item '${item.name}' created.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to create new item '${item.name}'.`
            : `Unable to create new item '${item.name}'.`;
        },
      },
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-4 toolbar">
        <h1>QR Items</h1>
        <div className="flex-fill me-2"></div>
        <RequiresRight
          requiredRight={RIGHT_ITEM_CREATE}
          userRights={initialRights}
        >
          <Button onClick={() => setModalShown(true)}>Add QR Item</Button>
        </RequiresRight>
      </div>
      <AddItemModal
        show={modalShown}
        onCancel={() => setModalShown(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default OverviewToolbar;
