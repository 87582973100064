import React from "react";
import { Modal, Button } from "react-bootstrap";

export interface ConfirmModalProps {
  header: string;
  text: string;
  confirmText: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

interface ConfirmModalState {
  isOpen: boolean;
}

class ConfirmModal extends React.Component<ConfirmModalProps, ConfirmModalState> {
  constructor(props: ConfirmModalProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  public open() {
    this.setState({ isOpen: true });
  }

  public confirm() {
    this.setState({ isOpen: false });
    this.props.onConfirm();
  }

  public cancel() {
    this.setState({ isOpen: false });

    if (this.props.onCancel)
      this.props.onCancel();
  }

  render() {
    return (
      <Modal show={this.state.isOpen} onHide={() => this.cancel()} centered>
        <Modal.Header>{this.props.header}</Modal.Header>
        <Modal.Body>
          {this.props.text}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.cancel()}>Cancel</Button>
          <Button variant="danger" onClick={() => this.confirm()}>{this.props.confirmText}</Button>
        </Modal.Footer>
      </Modal>
    );
  };
};

export default ConfirmModal;
