import React from "react";
import { Button, Card, Container, Row } from "react-bootstrap";
import QrLogo from "../../assets/images/logo.svg";
import { handleSignIn } from "../../auth/authProvider";

const AuthUnauthenticatedPage: React.FC = (props) => {
  return (
    <Container className="d-flex vh-100">
      <Row className="m-auto align-self-center">
        <Card className="text-center" style={{ width: "32rem" }}>
          <Card.Img variant="top" src={QrLogo} />
          <Card.Body>
            <Card.Title>Welcome!</Card.Title>
            <Card.Text>Please sign in to continue.</Card.Text>
            <Button
              variant="primary"
              className="ml-auto"
              onClick={() => handleSignIn()}
            >
              Sign in!
            </Button>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default AuthUnauthenticatedPage;
