import { StringUtils } from "@azure/msal-browser";
import FileContent from "../common/interfaces/FileContent";
import QrItem from "../qr-item/interfaces/QrItem";
import { qrItemQueries } from "../qr-item/qrItemQueries";
import CreateEntity from "./interfaces/CreateEntity";
import CreateEntityVersion from "./interfaces/CreateEntityVersion";
import Entity from "./interfaces/Entity";
import EntityVersion from "./interfaces/EntityVersion";

export const entityQueries = qrItemQueries
  .enhanceEndpoints({ addTagTypes: ["Entities"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Retrieve an entity by its id
      getEntityById: builder.query<Entity, number>({
        query: (entityId) => `entities/${entityId}`,
        providesTags: (result) => [{ type: "Entities", id: result?.id }],
      }),

      // Retrieve the different versions of an entity
      getEntityVersions: builder.query<EntityVersion[], number>({
        query: (entityId) => `entities/${entityId}/versions`,
        providesTags: (_result, _error, id) => [{ type: "Entities", id }],
      }),
      getEntityContent: builder.query<
        FileContent,
        { entityId: number; versionId?: StringUtils }
      >({
        query: (args) => ({
          url: `entities/${args.entityId}/content`,
          params: { version: args.versionId },
        }),
        providesTags: (_result, _error, args) => [
          { type: "Entities", id: args.entityId },
        ],
      }),

      // Upload a new version of the associated entity content
      putEntityContent: builder.mutation<
        Entity,
        { newVersion: CreateEntityVersion; entityId: number }
      >({
        query: (args) => ({
          url: `entities/${args.entityId}/content`,
          method: "PUT",
          body: args.newVersion,
        }),
        invalidatesTags: (result) => [
          { type: "Entities", id: result?.id },
          {
            type: "OrganizationMetrics",
            id: result?.item?.company.organization.id,
          },
        ],
      }),

      // Update an entity
      putEntity: builder.mutation<Entity, Entity>({
        query: (args) => ({
          url: `entities/${args.id}`,
          method: "PUT",
          body: args,
        }),
        invalidatesTags: (result) => [
          { type: "Entities", id: result?.id },
          { type: "QrItems", id: result?.itemId },
        ],
      }),

      // Update an entity with a new parent
      PutParent: builder.mutation<
        Entity,
        { entityId: number; newParentEntityId?: number; itemId: number }
      >({
        query: (args) => ({
          url: `entities/${args.entityId}/parent`,
          method: "PUT",
          body: {
            id: args.entityId,
            entityId: args.newParentEntityId,
            itemId: args.itemId,
          },
        }),
        invalidatesTags: (_result, _error, args) => [
          { type: "QrItems", id: args.itemId },
        ],
      }),

      // Post an entity
      PostEntity: builder.mutation<Entity, CreateEntity>({
        query: (args) => ({
          url: `entities`,
          method: "POST",
          body: args,
        }),
        invalidatesTags: (result) => [
          { type: "QrItems", id: result?.itemId },
          {
            type: "OrganizationMetrics",
            id: result?.item?.company.organization.id,
          },
        ],
      }),

      // Delete an entity
      DeleteEntity: builder.mutation<
        number,
        { entityId: number; item?: QrItem }
      >({
        query: (args) => ({
          url: `entities/${args.entityId}`,
          method: "DELETE",
        }),
        invalidatesTags: (_result, _error, args) => [
          { type: "QrItems", id: args.item?.id },
          {
            type: "OrganizationMetrics",
            id: args.item?.company.organization.id,
          },
        ],
      }),
    }),
  });

export const {
  useLazyGetEntityByIdQuery,
  useGetEntityVersionsQuery,
  useGetEntityContentQuery,
  useLazyGetEntityContentQuery,
  usePutEntityMutation,
  usePutEntityContentMutation,
  usePutParentMutation,
  usePostEntityMutation,
  useDeleteEntityMutation,
} = entityQueries;
