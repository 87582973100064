import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Company from "../../company/interfaces/Company";
import { Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { useLazyGetCompaniesQuery } from "../../company/companyQueries";
import { toFileContent } from "../../common/Utilities/Utils";
import CreateQrItem from "../interfaces/CreateQrItem";

export interface AddItemModalProps {
  show: boolean;
  onSubmit: (item: CreateQrItem) => void;
  onCancel: () => void;
}

const IMAGE_FORMATS = ["image/png", "image/jpg", "image/jpeg", "image/gif"];

const AddItemModal: React.FC<AddItemModalProps> = (props) => {
  const [getCompanies, { data: companies }] = useLazyGetCompaniesQuery();

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <Modal show={props.show} centered onEnter={() => getCompanies()}>
      <Formik
        initialValues={{
          name: "",
          description: "",
          companyId: -1,
          file: undefined,
        }}
        validationSchema={yup.object({
          name: yup.string().required("Please enter a valid name"),
          description: yup.string(),
          companyId: yup.number().positive("Please select a company"),
          file: yup
            .mixed()
            .nullable()
            .test(
              "file",
              "Please upload an image",
              (value) => !value || IMAGE_FORMATS.includes(value?.type)
            ),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          const uploadedImage: File | undefined = values.file;

          const created: CreateQrItem = {
            ...values,
            image: uploadedImage
              ? await toFileContent(uploadedImage)
              : undefined,
          };

          props.onSubmit(created);
          setSubmitting(false);
        }}
      >
        {({ handleChange, values, errors, touched, dirty, setFieldValue }) => (
          <FormikForm noValidate>
            <Modal.Header>Add QR Item</Modal.Header>
            <Modal.Body>
              <Form.Label>QR Item Name</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  required
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  isInvalid={touched.description && !!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  as="select"
                  name="companyId"
                  onChange={handleChange}
                  value={values.companyId}
                  required
                  defaultValue={-1}
                  isInvalid={touched.companyId && !!errors.companyId}
                >
                  <option disabled hidden value={-1}>
                    Select a company
                  </option>
                  {companies?.map((c: Company) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.companyId}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Banner image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Select an image..."
                  name="file"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.currentTarget.files) {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }
                  }}
                  accept="image/*"
                  isInvalid={touched.file && !!errors.file}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={!dirty}>
                Add QR Item
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddItemModal;
