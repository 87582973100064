import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteButton from "../../common/DeleteButton";
import {
  useDeleteItemTemplateMutation,
  useGetItemTemplateByOrganizationIdQuery,
  usePostItemTemplateMutation,
  usePutItemTemplateMutation,
} from "../api/itemTemplateQueries";
import CreateItemTemplate from "../interfaces/CreateItemTemplate";
import ItemTemplate from "../interfaces/ItemTemplate";
import TemplateFolder from "../interfaces/TemplateFolder";
import TemplateTree from "./TemplateTree";
import Toolbar from "../../common/Toolbar";
import RequiresRight from "../../auth/RequiresRight";
import {
  RIGHT_ITEM_TEMPLATE_CREATE,
  RIGHT_ITEM_TEMPLATE_DELETE,
  RIGHT_ITEM_TEMPLATE_UPDATE,
} from "../../auth/rights";
import checkRight from "../../auth/checkRight";
import Organization from "../interfaces/Organization";
import usePrompt from "../../common/Prompt";

export interface ItemTemplateConfigurationProps {
  organization: Organization;
}

const ItemTemplateConfiguration: React.FC<ItemTemplateConfigurationProps> = ({
  organization,
}) => {
  const { data: initialItemTemplate } = useGetItemTemplateByOrganizationIdQuery(
    organization.id
  );

  // Keep the template in memory until manually saving it to prevent unnecessary api calls
  const [template, setTemplate] = useState<ItemTemplate>({
    id: -1,
    organization,
    template: [],
  });
  const [selectedFolder, setSelectedFolder] = useState<TemplateFolder>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  useEffect(() => {
    if (initialItemTemplate) setTemplate(initialItemTemplate);
  }, [initialItemTemplate, organization]);

  usePrompt(
    "You have unsaved changes in your item template, are you sure you want to discard them?",
    hasUnsavedChanges
  );

  const [postItemTemplate] = usePostItemTemplateMutation();
  const [putItemTemplate] = usePutItemTemplateMutation();
  const [deleteItemTemplate] = useDeleteItemTemplateMutation();

  if (!organization) return <h4>No organization selected.</h4>;

  const handleSave = () => {
    if (template && template.id !== -1) {
      const promise = putItemTemplate(template).unwrap();
      toast.promise(promise, {
        pending: `Saving item template for organization ${organization.name}.`,
        success: {
          render(toast) {
            setHasUnsavedChanges(false);
            return `Item template for ${organization.name} successfully saved.`;
          },
        },
        error: {
          render(toast) {
            const data = toast.data as any;
            return data.originalStatus === 401
              ? `Not allowed to save item template for '${organization.name}'.`
              : `Unable to save item template for '${organization.name}'.`;
          },
        },
      });
    } else {
      const newTemplate: CreateItemTemplate = {
        ...template,
        organizationId: template.organization.id,
      };
      const promise = postItemTemplate(newTemplate).unwrap();
      toast.promise(promise, {
        pending: `Saving item template for organization ${organization.name}.`,
        success: {
          render(toast) {
            setHasUnsavedChanges(false);
            return `Item template for ${organization.name} successfully saved.`;
          },
        },
        error: {
          render(toast) {
            const data = toast.data as any;
            return data.originalStatus === 401
              ? `Not allowed to save item template for '${organization.name}'.`
              : `Unable to save item template for '${organization.name}'.`;
          },
        },
      });
    }
  };

  const handleAddFolder = () => {
    if (template.template.some((folder) => folder.displayText === "New"))
      return;

    const newFolder: TemplateFolder = {
      displayText: "New",
    };
    const newTemplate: ItemTemplate = {
      id: template.id,
      organization: template.organization,
      template: [newFolder].concat(template.template),
    };

    setTemplate(newTemplate);
    setHasUnsavedChanges(true);
  };

  const handleChange = (folders: TemplateFolder[]) => {
    if (!template) return;

    const updatedTemplate = { ...template, template: folders };

    // The posting will be manual action
    setTemplate(updatedTemplate);
    setHasUnsavedChanges(true);
  };

  const handleDeleteFolder = () => {
    if (!selectedFolder) return;

    const newTemplate: ItemTemplate = {
      id: template.id,
      organization: template.organization,
      template: template.template.filter(
        (folder) => folder.displayText !== selectedFolder.displayText
      ),
    };
    setTemplate(newTemplate);
    setHasUnsavedChanges(true);
  };

  const handleDeleteItemTemplate = () => {
    if (!template) return;
    const promise = deleteItemTemplate(template.id)
      .unwrap()
      .then(() => {
        // Update the data source of the tree list here
        // As the tree list will not re-render an empty list correctly otherwise
        setTemplate({
          id: -1,
          organization,
          template: [],
        });
      });

    toast.promise(promise, {
      pending: `Deleting item template for organization ${organization.name}.`,
      success: {
        render(toast) {
          setHasUnsavedChanges(false);
          return `Item template for ${organization.name} successfully deleted.`;
        },
      },
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.originalStatus === 401
            ? `Not allowed to delete item template for '${organization.name}'.`
            : `Unable to delete item template for '${organization.name}'.`;
        },
      },
    });
  };

  return (
    <>
      <Toolbar>
        <RequiresRight
          requiredRight={
            template.id === -1
              ? RIGHT_ITEM_TEMPLATE_CREATE
              : RIGHT_ITEM_TEMPLATE_UPDATE
          }
          userRights={organization.rights}
        >
          <Button onClick={handleAddFolder} variant="outline-secondary">
            Add folder
          </Button>
          <Button
            variant="outline-danger"
            onClick={handleDeleteFolder}
            disabled={!selectedFolder}
          >
            Delete folder
          </Button>
        </RequiresRight>
        <RequiresRight
          requiredRight={
            template.id === -1
              ? RIGHT_ITEM_TEMPLATE_CREATE
              : RIGHT_ITEM_TEMPLATE_UPDATE
          }
          userRights={organization.rights}
        >
          <Button
            variant="success"
            onClick={handleSave}
            disabled={!hasUnsavedChanges}
          >
            Save Template
          </Button>
        </RequiresRight>
        <RequiresRight
          requiredRight={RIGHT_ITEM_TEMPLATE_DELETE}
          userRights={organization.rights}
        >
          <DeleteButton
            disabled={template.id === -1}
            onDelete={handleDeleteItemTemplate}
            type="Item Template"
            text={`Are you sure you want to delete the item template for this organization?`}
          ></DeleteButton>
        </RequiresRight>
      </Toolbar>
      <TemplateTree
        folders={template.template}
        onChange={handleChange}
        onNodeSelected={setSelectedFolder}
        allowEdit={
          (template.id === -1 &&
            checkRight({
              requiredRight: RIGHT_ITEM_TEMPLATE_CREATE,
              userRights: organization.rights,
            })) ||
          checkRight({
            requiredRight: RIGHT_ITEM_TEMPLATE_UPDATE,
            userRights: organization.rights,
          })
        }
      ></TemplateTree>
    </>
  );
};

export default ItemTemplateConfiguration;
