import React from "react";
import checkRight from "./checkRight";

export interface RequiresRightProps {
  requiredRight: string;
  userRights?: string[];
  invalidComponent?: React.ReactNode;
}

const RequiresRight: React.FC<RequiresRightProps> = (props) => {
  const showContent = checkRight({ ...props });
  return showContent ? <>{props.children}</> : <>{props.invalidComponent}</>;
};

export default RequiresRight;
