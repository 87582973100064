import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import SimpleCard from "../common/SimpleCard";
import QrCard from "../qr-item/overview/QrCard";
import RecentQrItem from "./RecentQrItem";

export interface ItemsHistoryCardsProps {
  items?: RecentQrItem[];
  isLoading: boolean;
}

const ItemsHistoryCards: React.FC<ItemsHistoryCardsProps> = (props) => {
  const dataAvailable = props.items && props.items?.length > 0;

  return (
    <>
      {dataAvailable ? (
        <Card>
          <Card.Header>Recently viewed items</Card.Header>
          <Card.Body>
            <Row xs={1} md={2} lg={4} xl={5} className="g-3">
              {props.items?.map((item) => (
                <Col key={item.item.id} className="home">
                  <QrCard item={item.item} />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <SimpleCard
          headerText="Recently viewed items"
          loadingText="Loading recent items..."
          displayText="No items available"
          isLoading={props.isLoading}
        />
      )}
    </>
  );
};

export default ItemsHistoryCards;
