import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import "./paginator.css";

export interface PaginatorProps {
  currentPage: number;
  totalItems?: number;

  itemsPerPage: number;

  sortOption?: string;
  sortOptions?: { value: string; description: string }[];

  setPageNumber: (pageNumber: number) => void;
  setPageSize?: (pageSize: number) => void;
  setSortOption?: (option: string) => void;
}

const Paginator: React.FC<PaginatorProps> = (props) => {
  const [itemsPerPage, setItemsPerPage] = useState<number>(props.itemsPerPage);
  const [sortOption, setSortOption] = useState<string | undefined>(
    props.sortOption
  );

  useEffect(() => {
    const totalPages = Math.ceil((props.totalItems ?? 0) / itemsPerPage);
    if (props.currentPage > totalPages) props.setPageNumber(totalPages);
  }, [props.totalItems]);

  useEffect(() => {
    if (!props.setPageSize) return;

    const totalPages = Math.ceil((props.totalItems ?? 0) / itemsPerPage);
    if (props.currentPage > totalPages) props.setPageNumber(totalPages);

    props.setPageSize(itemsPerPage);
  }, [itemsPerPage]);

  useEffect(() => {
    if (!props.setSortOption) return;
    if (!sortOption) return;

    props.setSortOption(sortOption);
  }, [sortOption]);

  return (
    <div className="paginator d-flex justify-content-between">
      <div className="me-3">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={props.currentPage}
          itemsCountPerPage={props.itemsPerPage}
          totalItemsCount={props.totalItems ?? 0}
          prevPageText={`‹`}
          nextPageText={`›`}
          onChange={(e) => {
            props.setPageNumber(Number(e));
          }}
        />
      </div>
      <div className="flex-fill" />
    </div>
  );
};

export default Paginator;
