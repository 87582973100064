import React, { useState } from "react";
import QrCodeModal from "../modals/QrCodeModal";
import { AwesomeQRCode } from "@awesomeqr/react";
import QrItem from "../interfaces/QrItem";

export interface QrItemHeaderProps {
  item: QrItem;
}

const QrItemHeader: React.FC<QrItemHeaderProps> = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="d-flex m-2 justify-content-between">
      <div className="flex-fill">
        <p>
          {props.item.company.name}
          {props.item.description ? " - " : ""}
          {props.item.description}
        </p>
      </div>
      <div
        className=""
        style={{ width: "100px", height: "100px" }}
        onClick={handleShow}
        role="button"
      >
        <AwesomeQRCode
          options={{
            text: props.item.code,
          }}
        />
      </div>
      <QrCodeModal
        show={show}
        onClose={handleClose}
        title={props.item.name}
        itemCode={props.item.code}
      />
    </div>
  );
};

export default QrItemHeader;
