import React from "react";

export interface PageSizeSelectorProps {
  itemsPerPage: number;
  pageSizes: number[];

  setPageSize: (pageSize: number) => void;
}

const PageSizeSelector: React.FC<PageSizeSelectorProps> = (props) => {
  return (
    <select
      className="form-select mb-1"
      onChange={(e) => props.setPageSize(Number(e.target.value))}
      value={props.itemsPerPage}
    >
      {props.pageSizes.map((size) => {
        return (
          <option value={size} key={size}>
            {size} per page
          </option>
        );
      })}
    </select>
  );
};

export default PageSizeSelector;
