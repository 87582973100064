import { DdtChangeEventArgs, DropDownTree, DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useRef, useState } from "react";
import "./filter.css";

export interface FilterProps {
  dataSource: {[key: string]: Object}[]
  groupBy?: string
  placeholder?: string

  onFilter: (filteredIds: number[]) => void;
}

interface FilterOption {
  id: Object
  name: String
  parentId?: Object
  hasChild?: boolean
}

const Filter: React.FC<FilterProps> = (props) => {
  const treeComponent = useRef<DropDownTreeComponent>(null);
  const [dataSource, setDataSource] = useState<FilterOption[]>([]);

  useEffect(() => {
    const categories = [...new Set(props.dataSource.map(d => d.Category))];
    const data = categories.map(c => ({ id: c, name: c, hasChild: true} as FilterOption));
    props.dataSource.forEach(d => data.push({ id: d.Id, name: d.Name, parentId: d.Category, hasChild: false } as FilterOption));
    setDataSource(data);
  }, [props.dataSource]);

  return (
    <DropDownTreeComponent
      ref={treeComponent}
      fields={{ dataSource: dataSource as any[], value: 'id', text: 'name', parentValue: 'parentId', hasChildren: 'hasChild' }}
      treeSettings={{ autoCheck: true }}
      placeholder={props.placeholder ?? "Filter..."}
      customTemplate="${value.length} selected"
      mode="Custom"
      showCheckBox={true}
      changeOnBlur={false}
      value={[]}
      change={(e: DdtChangeEventArgs) => props.onFilter(e.value.filter(v => !isNaN(+v)).map(v => +v))}
      popupWidth={400} />
  );
};

export default Filter;
