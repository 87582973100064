import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Entity from "../interfaces/Entity";
import "../../common/tree/tree.css";
import {
  DragLayerMonitorProps,
  DropOptions,
  NodeModel,
  Tree,
  TreeMethods,
} from "@minoru/react-dnd-treeview";
import FileTreeNode from "./FileTreeNode";
import FileTreeDragNode from "./FileTreeDragNode";
import { selectSelectedEntity } from "../entitySlice";
import { usePutParentMutation } from "../entityQueries";

interface QrItemFilesTreeProps {
  itemId: number;
  entities: Entity[];
  readonly: boolean;
  isLoading?: boolean;
  onNodeSelected: (entityId: number) => void;
}

const QrItemFilesTree: React.FC<QrItemFilesTreeProps> = (props) => {
  const selectedEntity = useAppSelector(selectSelectedEntity);

  const [putParent] = usePutParentMutation();

  const refTree = useRef<TreeMethods>(null);
  const [treeNodes, setTreeNodes] = useState<NodeModel<Entity>[]>();
  const [selectedNode, setSelectedNode] = useState<Entity | undefined>();

  useEffect(() => {
    const entitiesToDisplay = props.entities.map((entity) => {
      const nodeModel: NodeModel<Entity> = {
        id: entity.id,
        parent: entity.entityId ?? 0,
        text: entity.displayName,
        droppable: entity.fileType === "Folder",
        data: entity,
      };

      return nodeModel;
    });

    setTreeNodes(entitiesToDisplay);
  }, [selectedEntity, props.entities]);

  useEffect(() => {
    let nodeToSelect = selectedEntity;
    if (!props.entities.find((node) => node.id === selectedEntity?.id))
      nodeToSelect = props.entities.find(
        (node) => node.id === selectedEntity?.entityId
      );

    if (!nodeToSelect) return;

    setSelectedNode(nodeToSelect);
    props.onNodeSelected(nodeToSelect.id);
  }, [treeNodes]);

  const handleNodeDropped = async (args: DropOptions<Entity>) => {
    const draggedEntityId = +args.dragSourceId;
    const parentEntityId = +args.dropTargetId;

    await putParent({
      entityId: draggedEntityId,
      newParentEntityId: parentEntityId === 0 ? undefined : parentEntityId,
      itemId: props.itemId,
    });

    setSelectedNode(
      props.entities.find((entity) => entity.id === draggedEntityId)
    );
  };

  return (
    <>
      {treeNodes && (
        <Tree
          ref={refTree}
          tree={treeNodes}
          rootId={0}
          classes={{
            root: "tree-root",
            draggingSource: "dragging-source",
            dropTarget: "drop-target",
          }}
          sort={(a: NodeModel<Entity>, b: NodeModel<Entity>) => {
            return a.text.localeCompare(b.text);
          }}
          render={(node: NodeModel<Entity>, { depth, isOpen, onToggle }) => (
            <FileTreeNode
              node={node}
              depth={depth}
              isOpen={isOpen}
              onToggle={onToggle}
              isSelected={node.id === selectedNode?.id}
              hasChildren={treeNodes.some((n) => n.parent === node.id)}
              onSelect={(args) => {
                if (!node.data?.id) return;
                setSelectedNode(node.data);
                props.onNodeSelected(node.data.id);
              }}
            />
          )}
          canDrag={(args) => !props.readonly}
          dragPreviewRender={(monitorProps: DragLayerMonitorProps<Entity>) => (
            <FileTreeDragNode monitorProps={monitorProps} />
          )}
          onDrop={(args, options) => handleNodeDropped(options)}
          initialOpen={true}
        />
      )}
    </>
  );
};

export default QrItemFilesTree;
