import {
  faExternalLinkAlt,
  faFile,
  faFilePdf,
  faFolder,
  faImage,
  faQrcode,
  faUnlink,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragLayerMonitorProps } from "@minoru/react-dnd-treeview";
import Entity from "../interfaces/Entity";
import { FileType } from "../interfaces/FileType";
import "../../common/tree/treeDragNode.css";

interface FileTreeDragNodeProps {
  monitorProps: DragLayerMonitorProps<Entity>;
}

const FileTreeDragNode: React.FC<FileTreeDragNodeProps> = (props) => {
  const entity = props.monitorProps.item;

  const determineIcon = (
    nodeType: FileType,
    mimeType?: string
  ): IconDefinition => {
    switch (nodeType) {
      case FileType.Folder:
        return faFolder;
      case FileType.Weblink:
        return faExternalLinkAlt;
      case FileType.File:
        if (mimeType) {
          if (mimeType.includes("image")) return faImage;
          if (mimeType.includes("pdf")) return faFilePdf;
        }
        return faFile;
      case FileType.Item:
        return faQrcode;
      default:
        return faUnlink;
    }
  };

  if (!entity.data) return <></>;

  return (
    <div className={`tree-drag-node`}>
      <div className="tree-node-data">
        <div className="node-type-icon">
          <FontAwesomeIcon
            icon={determineIcon(entity.data.fileType, entity.data.mimeType)}
            className="align-self-center me-2"
          />
        </div>
        <div className="node-text">{entity.text}</div>
      </div>
    </div>
  );
};

export default FileTreeDragNode;
