import {
  faAngleRight,
  faExternalLinkAlt,
  faFile,
  faFilePdf,
  faFolder,
  faImage,
  faQrcode,
  faUnlink,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import Entity from "../interfaces/Entity";
import { FileType } from "../interfaces/FileType";
import "../../common/tree/treeNode.css";

interface FileTreeNodeProps {
  node: NodeModel<Entity>;
  depth: number;
  isOpen: boolean;
  isSelected: boolean;
  hasChildren: boolean;
  onToggle: (id: NodeModel["id"]) => void;
  onSelect: (node: NodeModel) => void;
}

const FileTreeNode: React.FC<FileTreeNodeProps> = (props) => {
  const { id, droppable, data } = props.node;
  const indent = props.depth * 25;

  const determineIcon = (
    nodeType: FileType,
    mimeType?: string
  ): IconDefinition => {
    switch (nodeType) {
      case FileType.Folder:
        return faFolder;
      case FileType.Weblink:
        return faExternalLinkAlt;
      case FileType.File:
        if (mimeType) {
          if (mimeType.includes("image")) return faImage;
          if (mimeType.includes("pdf")) return faFilePdf;
        }
        return faFile;
      case FileType.Item:
        return faQrcode;
      default:
        return faFile;
    }
  };

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  if (!props.node.data) return <></>;

  return (
    <div
      className={`tree-node root ${props.isSelected ? "selected" : ""}`}
      style={{ paddingInlineStart: indent }}
      {...dragOverProps}
    >
      <div
        className={`expand-icon-wrapper ${
          props.node.droppable ? "droppable" : ""
        } ${props.isOpen ? "open" : ""}`}
      >
        {props.node.droppable && props.hasChildren && (
          <div onClick={handleToggle}>
            <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
          </div>
        )}
      </div>
      <div
        className="tree-node-data"
        onMouseDown={() => props.onSelect(props.node)}
      >
        <div className="node-type-icon">
          <FontAwesomeIcon
            icon={determineIcon(
              props.node.data.fileType,
              props.node.data.mimeType
            )}
            className="align-self-center me-2"
          />
        </div>
        <div className="node-text">{props.node.text}</div>
      </div>
    </div>
  );
};

export default FileTreeNode;
