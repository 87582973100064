import React from "react";
import { Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAppDispatch } from "../../../app/hooks";
import { setSelectedEntity } from "../../entity/entitySlice";
import QrItem from "../interfaces/QrItem";
import { useGetItemImageQuery } from "../qrItemQueries";

interface QrCardProps {
  item: QrItem;
}

const QrCard: React.FC<QrCardProps> = ({ item }) => {
  const dispatch = useAppDispatch();

  const { data: image } = useGetItemImageQuery({
    itemId: item.id,
    fallback: true,
  });

  return (
    <LinkContainer style={{ cursor: "pointer" }} to={`/items/${item.slug}`}>
      <Card
        onClick={() => {
          dispatch(setSelectedEntity(undefined));
        }}
        className="qr-card shadow"
      >
        <div className="text-center p-2">
          <Card.Img
            className="card img"
            variant="top"
            src={
              image
                ? `data:${image.mimeType};base64,${image.content}`
                : `data:image/svg+xml;utf8,
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 100" height="100px" width="180px">
                    <style> .text {font: bold 20px sans-serif; fill: grey; } </style>
                    <rect width="180" height="100" style="fill:gray;stroke:dark-gray;fill-opacity:0.1;stroke-opacity:0.9" />
                    <text class="text" x="50%" y ="50%" dominant-baseline="middle" text-anchor="middle"> No image </text>
                  </svg>`
            }
          />
        </div>
        <Card.Body>
          <Card.Title className="text-truncate" title={item.name}>
            {item.name}
          </Card.Title>
          <Card.Subtitle className="itemCardSubtitle text-muted text-truncate">
            {item.company.name}
          </Card.Subtitle>
          <Card.Text
            className="itemCardDescription text-muted text-truncate"
            title={item.description}
          >
            {item.description}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Button variant="outline-primary float-end">Go to item</Button>
        </Card.Footer>
      </Card>
    </LinkContainer>
  );
};

export default QrCard;
