import React from "react";
import QrItemFileDetails from "./details/QrItemFileDetails";
import QrItemFilesTree from "./tree/QrItemFilesTree";
import { useAppDispatch } from "../../app/hooks";
import { setSelectedEntity } from "./entitySlice";
import { Card } from "react-bootstrap";
import Entity from "./interfaces/Entity";
import { RIGHT_ENTITY_UPDATE } from "../auth/rights";
import checkRight from "../auth/checkRight";
import SimpleCard from "../common/SimpleCard";

export enum NodeType {
  File,
  Folder,
  Link,
}

export interface QrItemFilesProps {
  itemId: number;
  entities?: Entity[];
  isLoading: boolean;
  userRights?: string[];
}

const QrItemFiles: React.FC<QrItemFilesProps> = (props) => {
  const dispatch = useAppDispatch();

  const nodeSelectedHandler = (entityId: number) => {
    const entity = props.entities?.find((e) => e.id === entityId);
    dispatch(setSelectedEntity(entity));
  };

  return (
    <div className="row">
      <div className="col-12">
        {props.entities ? (
          <Card>
            <Card.Header>
              <Card.Title>Files</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-6">
                  <QrItemFilesTree
                    itemId={props.itemId}
                    entities={props.entities}
                    onNodeSelected={nodeSelectedHandler}
                    readonly={
                      !checkRight({
                        requiredRight: RIGHT_ENTITY_UPDATE,
                        userRights: props.userRights,
                      })
                    }
                    isLoading={props.isLoading}
                  />
                </div>
                <div className="col-6">
                  <QrItemFileDetails userRights={props.userRights} />
                </div>
              </div>
            </Card.Body>
          </Card>
        ) : (
          <SimpleCard
            headerText="Files"
            displayText="Unable to retrieve files."
            loadingText="Retrieving files..."
            isLoading={props.isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default QrItemFiles;
