export interface CheckRightProps {
  requiredRight: string;
  userRights?: string[];
}

const checkRight = (props: CheckRightProps) => {
  if (!props.userRights) return false;
  return props.userRights.includes(props.requiredRight);
};

export default checkRight;
