import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import Entity from "./interfaces/Entity";
import { FileType } from "./interfaces/FileType";

export interface ItemState {
  selectedEntity?: Entity;
}

const initialState: ItemState = {
  selectedEntity: undefined,
};

export const itemSlice = createSlice({
  name: "entities",
  initialState,
  reducers: {
    setSelectedEntity: (state, action: PayloadAction<Entity | undefined>) => {
      state.selectedEntity = action.payload;
    },
  },
});

/**
 * Search through a collection of entities to find the nearest folder
 * of the selected entity.
 * @param entities entities collection to search through
 * @returns The identifier of the nearest folder entity
 */
export const selectSelectedEntityFolder =
  (entities?: Entity[]) => (state: RootState) => {
    if (!entities) return -1;

    const entity = state.entities.selectedEntity;
    const parentId =
      entity?.fileType === FileType.Folder
        ? entity.id
        : entities.find((f) => f.id === entity?.entityId)?.id;

    return parentId ? parentId : -1;
  };

export const selectSelectedEntity = (state: RootState) =>
  state.entities.selectedEntity;

export const { setSelectedEntity } = itemSlice.actions;

export default itemSlice.reducer;
