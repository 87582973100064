import PaginatedList from "../../api/PaginatedList";
import QueryParameters from "../../api/QueryParameters";
import { baseApi } from "../common/baseApi";
import EntityVersion from "../entity/interfaces/EntityVersion";
import Metric from "./interfaces/Metric";
import RecentQrItem from "./RecentQrItem";

export const homeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecentlyAccessedItems: builder.query<
      RecentQrItem[],
      { count: number; loadBanners: boolean }
    >({
      query: ({ count, loadBanners }) =>
        `metadata/recent-items?count=${count}&loadBanners=${loadBanners}`,
    }),
    getRecentlyUpdatedEntities: builder.query<PaginatedList<EntityVersion>, QueryParameters>({
      query: (params) => ({
          url: `metadata/updated-entities`,
          params
        })
    }),
    getUsedCapacityMetrics: builder.query<Metric, number>({
      query: (days) => `metadata/metric?days=${days}`,
    }),
  }),
});

export const {
  useGetRecentlyAccessedItemsQuery,
  useGetRecentlyUpdatedEntitiesQuery,
  useGetUsedCapacityMetricsQuery,
} = homeApi;
