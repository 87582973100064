import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  RowSelectEventArgs,
} from "@syncfusion/ej2-react-grids";
import React, { useEffect } from "react";
import Organization from "../interfaces/Organization";
import OrganizationsToolbar from "./OrganizationsToolbar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetOrganizationsQuery } from "../api/organizationQueries";
import SimpleCard from "../../common/SimpleCard";

const OrganizationsOverviewPage: React.FC = () => {
  const { data: organizations, isLoading, error } = useGetOrganizationsQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      const _error: any = error;
      toast.error(
        _error?.status === 401
          ? "Not allowed to view organizations."
          : "Unable to load organizations."
      );
    }
  }, [error]);

  const handleSelect = (args: RowSelectEventArgs) => {
    const organization = args.data as Organization;
    navigate(`/organizations/${organization?.slug}`);
  };

  return (
    <>
      <OrganizationsToolbar />
      {organizations && organizations.length > 0 ? (
        <GridComponent dataSource={organizations} rowSelected={handleSelect}>
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Name" width="75" />
          </ColumnsDirective>
        </GridComponent>
      ) : (
        <SimpleCard
          displayText="No organizations available"
          loadingText="Loading organizations..."
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default OrganizationsOverviewPage;
