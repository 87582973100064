import { Formik, Form as FormikForm, FormikErrors } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import RoleAssignment from "./interfaces/RoleAssignment";
import CreateRoleAssignment from "./interfaces/CreateRoleAssignment";
import User from "../auth/interfaces/User";
import Role from "../auth/interfaces/Role";
import { useGetRolesQuery } from "./api/roleQueries";
import { useGetUserByEmailMutation } from "./api/userQueries";

export interface AddRoleAssignmentModalProps {
  show: boolean;
  assignment: { itemId?: number; companyId?: number; organizationId?: number };
  currentRoleAssignments?: RoleAssignment[];
  onSubmit: (roleAssignment: CreateRoleAssignment) => void;
  onCancel: () => void;
}

const AddRoleAssignmentModal: React.FC<AddRoleAssignmentModalProps> = (
  props
) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const { data: roles } = useGetRolesQuery();

  const [getUserByEmailQuery] = useGetUserByEmailMutation();
  const [foundUser, setFoundUser] = useState<User | undefined>(undefined);
  const [availableRoles, setAvailableRoles] = useState<Role[] | undefined>(
    roles
  );

  useEffect(() => {
    if (!props.show) return;

    // When showing the modal, set the "is confirming" boolean to false and reset the "found user".
    setIsConfirming(false);
    setFoundUser(undefined);
  }, [props.show]);

  const onSubmit = async (
    assignment: CreateRoleAssignment,
    setFormErrors: (errors: FormikErrors<CreateRoleAssignment>) => void
  ) => {
    if (isConfirming) {
      props.onSubmit(assignment);
      return;
    }
    if (!assignment.mail) return;

    const result = await getUserByEmailQuery(assignment.mail).unwrap();

    if (result) {
      // If a user can be retrieved, check if the role to assign isn't already assigned.
      const userAvailableRoles = roles?.filter(
        (role: Role) =>
          !props.currentRoleAssignments
            ?.filter(
              (ra) =>
                ra.user.id === Number(result.id) &&
                // Exclude inherited assignments
                (ra.item?.id === props.assignment.itemId ||
                  ra.company?.id === props.assignment.companyId ||
                  ra.organization?.id === props.assignment.organizationId)
            )
            .map((ra) => ra.role.id)
            .includes(role.id)
      );
      setAvailableRoles(userAvailableRoles);

      assignment.userId = result.id;
      setFoundUser(result);
      setIsConfirming(true);
    } else {
      setFormErrors({ mail: "Unable to find user with this e-mailaddress." });
    }
  };

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <Modal show={props.show} centered>
      <Formik
        initialValues={{
          userId: -1,
          roleId: -1,
          mail: undefined,
          itemId: props.assignment.itemId,
          companyId: props.assignment.companyId,
          organizationId: props.assignment.organizationId,
        }}
        validationSchema={yup.object({
          mail: yup
            .string()
            .email("Please enter a valid e-mailaddress.")
            .required("Please enter an e-mailaddress."),
          roleId: isConfirming
            ? yup.number().positive("Please select a role.")
            : yup.number(),
        })}
        onSubmit={(
          values: CreateRoleAssignment,
          { setSubmitting, setErrors }
        ) => {
          onSubmit(values, setErrors);
          setSubmitting(false);
        }}
      >
        {({
          handleChange,
          setFieldValue,
          values,
          errors,
          touched,
          dirty,
          isValid,
        }) => (
          <FormikForm noValidate>
            <Modal.Header>Add Role assignment</Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>E-mailaddress</Form.Label>
                <Form.Control
                  type="mail"
                  placeholder="some@one.com"
                  name="mail"
                  onChange={(event) => {
                    // If the mailaddress is changed, reset the available roles to include all roles.
                    setAvailableRoles(roles);
                    handleChange(event);
                  }}
                  required
                  defaultValue={undefined}
                  isInvalid={touched.mail && !!errors.mail}
                  isValid={isConfirming}
                  disabled={isConfirming}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mail}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                  User found for e-mailaddress: {foundUser?.name}.
                </Form.Control.Feedback>
              </Form.Group>
              {isConfirming && (
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    name="roleId"
                    onChange={handleChange}
                    required
                    defaultValue={-1}
                    isInvalid={touched.roleId && !!errors.roleId}
                    disabled={!values.mail && values.mail !== ""}
                  >
                    <option disabled hidden value={-1}>
                      Select a role
                    </option>
                    {availableRoles &&
                      availableRoles.map((r: Role) => (
                        <>
                          <option key={r.id} value={r.id}>
                            {`${r.name}`}
                          </option>
                          <option
                            style={{ fontStyle: "italic", fontSize: "12px" }}
                            disabled
                          >{`└ ${r.description}`}</option>
                        </>
                      ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.roleId}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={!dirty || !isValid}>
                {isConfirming ? `Add role assignment` : `Continue`}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddRoleAssignmentModal;
