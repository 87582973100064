import React from "react";
import { Card, Spinner } from "react-bootstrap";

export interface SimpleCardProps {
  headerText?: string;
  isLoading: boolean;
  loadingText: string;
  displayText: string;
}

const SimpleCard: React.FC<SimpleCardProps> = (props) => {
  return (
    <Card>
      {props.headerText !== undefined && (
        <Card.Header>{props.headerText}</Card.Header>
      )}
      <Card.Body className="fst-italic text-muted">
        {props.isLoading ? (
          <>
            <Spinner animation="border" role="status" size="sm" />
            <span className="ms-2">{props.loadingText}</span>
          </>
        ) : (
          <>{props.displayText}</>
        )}
      </Card.Body>
    </Card>
  );
};

export default SimpleCard;
