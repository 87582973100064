import React, { useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import { NavLink } from "react-router-dom";
import { useGetEmptyItemsQuery } from "../qrItemQueries";
import QrItem from "../interfaces/QrItem";
import Paginator from "../../common/pagination/Paginator";
import { Spinner } from "react-bootstrap";

export interface EmptyItemsListProps {
  filters?: string[];
  showCompany?: boolean;
}

const EmptyItemsList: React.FC<EmptyItemsListProps> = (props) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageSize: number = 25;
  const { data: emptyItems, isLoading } = useGetEmptyItemsQuery({
    page: pageNumber,
    pageSize: pageSize,
    sorts: ["Company.Name", "Name"],
    filters: props.filters ?? [],
  });

  const itemLinkTemplate = (rowProps: QrItem) => {
    return (
      <div>
        <NavLink to={`/items/${rowProps.slug}`}>{rowProps.name}</NavLink>
      </div>
    );
  };

  const companyLinkTemplate = (rowProps: QrItem) => {
    return (
      <div>
        <NavLink to={`/companies/${rowProps.company.slug}`}>
          {rowProps.company.name}
        </NavLink>
      </div>
    );
  };

  return (
    <>
      {isLoading && (
        <Spinner
          animation="border"
          role="status"
          className="position-absolute top-50 start-50"
        />
      )}
      {!isLoading && (
        <>
          <GridComponent dataSource={emptyItems?.data}>
            <ColumnsDirective>
              {props.showCompany && (
                <ColumnDirective
                  field="company.name"
                  headerText="Company"
                  template={companyLinkTemplate}
                />
              )}
              <ColumnDirective
                field="name"
                headerText="Item"
                template={itemLinkTemplate}
              />
              <ColumnDirective field="description" headerText="Description" />
              <ColumnDirective
                field="createdUser.name"
                headerText="Created by"
              />
              <ColumnDirective
                field="creationTimestampZone"
                headerText="Created on"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
              />
            </ColumnsDirective>
          </GridComponent>
          <Paginator
            currentPage={pageNumber}
            totalItems={emptyItems?.totalCount}
            itemsPerPage={pageSize}
            setPageNumber={(pageNumber) => setPageNumber(pageNumber)}
          />
        </>
      )}
    </>
  );
};

export default EmptyItemsList;
