import React, { useEffect, useState } from "react";
import { Button, Form, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import RequiresRight from "../auth/RequiresRight";

export interface QrItemHeaderProps {
  text: string;
  onSubmit: (text: string) => void;
  requiredRight: string;
  userRights?: string[];
}

const EditableHeader: React.FC<QrItemHeaderProps> = (props) => {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(props.text);

  useEffect(() => {
    setText(props.text);
  }, [props.text]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit(text);
    setEditing(false);
  };

  return (
    <>
      {editing ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="d-flex">
            <Form.Control
              autoFocus={true}
              value={text}
              onChange={handleOnChange}
            />
            <ButtonGroup>
              <Button variant="outline-success" type="submit">
                <FontAwesomeIcon icon={faCheck} />
              </Button>
              <Button
                variant="outline-danger"
                type="reset"
                onClick={() => {
                  setEditing(false);
                  setText(props.text);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </ButtonGroup>
          </Form.Group>
        </Form>
      ) : (
        <RequiresRight
          requiredRight={props.requiredRight}
          userRights={props.userRights}
          invalidComponent={<h1 className="h1 editable-header">{text}</h1>}
        >
          <h1 className="h1 editable-header" onClick={() => setEditing(true)}>
            {text}
            {"  "}
            <FontAwesomeIcon
              className="editable-icon"
              icon={faPen}
            ></FontAwesomeIcon>
          </h1>
        </RequiresRight>
      )}
    </>
  );
};

export default EditableHeader;
