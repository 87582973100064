import React from "react";
import { Tabs, Tab, Spinner } from "react-bootstrap";
import Toolbar from "../../common/Toolbar";
import DeleteButton from "../../common/DeleteButton";
import { useParams, useNavigate } from "react-router-dom";
import EditableHeader from "../../common/EditableHeader";
import { toast } from "react-toastify";
import {
  RIGHT_ITEM_TEMPLATE_VIEW,
  RIGHT_ORGANIZATION_DELETE,
  RIGHT_ORGANIZATION_UPDATE,
  RIGHT_USERS_VIEW,
} from "../../auth/rights";
import RequiresRight from "../../auth/RequiresRight";
import OrganizationMetrics from "./OrganizationMetrics";
import OrganizationCompanies from "./OrganizationCompanies";
import ItemTemplateConfiguration from "../item-templates/ItemTemplateConfiguration";
import checkRight from "../../auth/checkRight";
import {
  useDeleteOrganizationMutation,
  useGetOrganizationBySlugQuery,
  usePutOrganizationMutation,
} from "../api/organizationQueries";
import RoleAssignmentsView from "../../role-assignment/RoleAssignmentsView";
import EmptyItemsList from "../../qr-item/empty/EmptyItemsList";

const OrganizationPage: React.FC = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  if (!slug) {
    throw new Error("Slug must be defined.");
  }

  const { data: organization, isLoading } = useGetOrganizationBySlugQuery(slug);
  const [putOrganization] = usePutOrganizationMutation();
  const [deleteOrganization] = useDeleteOrganizationMutation();

  if (isLoading)
    return (
      <Spinner
        animation="border"
        role="status"
        className="position-absolute top-50 start-50"
      />
    );
  if (!organization) return <h4>No organization selected.</h4>;

  const handleDeleteOrganization = () => {
    if (!organization) return;

    const promise = deleteOrganization(organization.id)
      .unwrap()
      .then(() => navigate("../organizations"));
    toast.promise(promise, {
      pending: `Deleting organization '${organization.name}' and its items ...`,
      success: `${organization.name} successfully deleted.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to delete organization '${organization.name}'.`
            : `Unable to delete organization '${organization.name}'.`;
        },
      },
    });
  };

  const handleOrganizationNameChange = (name: string) => {
    const updatedOrganization = { ...organization, name };

    const updatePromise = putOrganization(updatedOrganization).unwrap();
    toast.promise(updatePromise, {
      pending: `Updating organization '${name}' ...`,
      success: `Organization '${name}' updated.`,
      error: {
        render(toast) {
          const data = toast.data as any;
          return data.message === "Unauthorized"
            ? `Not allowed to update organization '${name}'.`
            : `Unable to update organization '${name}'.`;
        },
      },
    });
  };

  return (
    <Tabs defaultActiveKey="details" className="mb-3" variant="tabs">
      <Tab eventKey="details" title="Details">
        <Toolbar>
          <EditableHeader
            requiredRight={RIGHT_ORGANIZATION_UPDATE}
            userRights={organization.rights}
            text={organization.name}
            onSubmit={handleOrganizationNameChange}
          />
          <RequiresRight
            requiredRight={RIGHT_ORGANIZATION_DELETE}
            userRights={organization.rights}
          >
            <DeleteButton
              disabled={!organization}
              type="Organization"
              text={`Are you sure you want to delete '${organization?.name}' and its companies and items?`}
              onDelete={handleDeleteOrganization}
            />
          </RequiresRight>
        </Toolbar>
        <OrganizationMetrics organization={organization} />
        <br />
        <OrganizationCompanies organization={organization} />
      </Tab>
      <Tab eventKey="empty-items" title="Empty items">
        <h1>{organization.name}</h1>
        <p>The following items contain no files:</p>
        <EmptyItemsList
          filters={[`Company.OrganizationId==${organization ? organization.id : -1}`]}
          showCompany={true}
        />
      </Tab>
      {checkRight({
        requiredRight: RIGHT_USERS_VIEW,
        userRights: organization.rights,
      }) && (
        <Tab eventKey="role-assignments" title="Role Assignments">
          <RoleAssignmentsView
            title={organization.name}
            organizationId={organization.id}
            userRights={organization.rights}
          />
        </Tab>
      )}
      {checkRight({
        requiredRight: RIGHT_ITEM_TEMPLATE_VIEW,
        userRights: organization.rights,
      }) && (
        <Tab eventKey="item-templates" title="Item Template">
          <ItemTemplateConfiguration organization={organization} />
        </Tab>
      )}
    </Tabs>
  );
};

export default OrganizationPage;
